<template>
  <div v-if="findTennnis">
    <div
      class="titleContainer"
      v-bind:class="{ opacity: findTennnis.status === 'FINISH' }"
    >
      <p class="title">{{ findTennnis.title }}</p>
    </div>
    <div v-if="findTennnis.status === 'LIVE'" class="live blinks">
      {{ $t("game.live") }}
    </div>
    <a-row>
      <a-col
        :xs="{ span: 8 }"
        :sm="{ span: 8 }"
        :md="{ span: 10 }"
        :lg="{ span: 10 }"
      >
        <a-typography-paragraph
          class="name"
          :copyable="{ tooltip: false }"
          :content="playerName(findTennnis.players.player1.members)"
        >
          <template #copyableIcon>
            <DribbbleOutlined :style="{ color: 'green' }" />
          </template>
        </a-typography-paragraph>
      </a-col>
      <a-col
        :xs="{ span: 16 }"
        :sm="{ span: 16 }"
        :md="{ span: 14 }"
        :lg="{ span: 14 }"
      >
        <MiniSet
          v-if="
            findTennnis.status === 'LIVE' || findTennnis.status === 'FINISH'
          "
          :player="findTennnis.players.player1"
          :end="
            findTennnis.players.player1.points.win ||
            findTennnis.players.player2.points.win
          "
          :game="findTennnis.game.value"
        />
      </a-col>
    </a-row>

    <p class="name" v-bind:class="{ opacity: findTennnis.status === 'FINISH' }">
      VS
    </p>

    <a-row>
      <a-col
        :xs="{ span: 8 }"
        :sm="{ span: 8 }"
        :md="{ span: 10 }"
        :lg="{ span: 10 }"
      >
        <a-typography-paragraph
          class="name"
          :copyable="{ tooltip: false }"
          :content="playerName(findTennnis.players.player2.members)"
        >
          <template #copyableIcon>
            <DribbbleOutlined :style="{ color: 'green' }" />
          </template>
        </a-typography-paragraph>
      </a-col>

      <a-col
        :xs="{ span: 16 }"
        :sm="{ span: 16 }"
        :md="{ span: 14 }"
        :lg="{ span: 14 }"
      >
        <MiniSet
          v-if="
            findTennnis.status === 'LIVE' || findTennnis.status === 'FINISH'
          "
          :player="findTennnis.players.player2"
          :end="
            findTennnis.players.player1.points.win ||
            findTennnis.players.player2.points.win
          "
          :game="findTennnis.game.value"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import MiniSet from "../components/MiniSet.vue";
import { DribbbleOutlined } from "@ant-design/icons-vue";
export default {
  name: "GameLive",
  props: {
    id: Number,
  },
  components: {
    MiniSet,
    DribbbleOutlined,
  },
  data() {
    return {};
  },
  computed: {
    findTennnis() {
      const game = this.$store.state.tennis.findIndex(
        (obj) => obj.code == this.id
      );
      return this.$store.state.tennis[game];
    },
  },
  mounted() {},
  methods: {
    playerName(members) {
      const { nameA, nameB } = members;
      let player = "";
      if (nameA) {
        player = nameA;
      }
      if (nameB) {
        player += " / " + nameB;
      }
      return player;
    },
  },
};
</script>
<style scoped>
.opacity {
  opacity: 0.5;
}

.live {
  background-color: #ee2912;
  color: white;
  width: 20%;
  font-size: 1.8vw;
}

.titleContainer {
  background-color: #2f74b5;
  color: #000111;
  border-style: solid;
  border-color: rgb(0, 0, 0);
}

.title {
  background-color: #81817a;
  margin: 0px;
  font-weight: bold;
  /* font-size: 1.2vw; */
  font-size: var(--font-size, 0.8em);
}
.name {
  margin: 0px;
  /* font-size: 1vw; */
  display: inline-block;
  vertical-align: top;
  font-size: var(--font-size, 0.55em);
}

.vs {
  font-size: var(--font-size, 0.5em);
}

.ball {
  width: 15%;
  height: 25%;
}

.blinks {
  animation-name: flicker;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: flicker;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes flicker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flicker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flicker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
