<template>
  <div>
    <a-list :grid="{ gutter: 16, column: 4 }" :data-source="diplayTennis">
      <template #renderItem="{ item }">
        <a-list-item>
          <Tennis
            :code="item.code"
            :title="item.title"
            :players="item.players"
            :canEdit="false"
            :canCopy="true"
            :canRefresh="false"
          />
        </a-list-item>
      </template>
    </a-list>
    <a-modal v-model:visible="visible" :title="this.$t('tennis.live')">
      <template #footer> </template>
      <CreateLive @closeModal="closeModal" />
    </a-modal>
    <a-affix
      :offset-bottom="bottom"
      class="right"
      v-if="diplayTennis.length <= maxCourt - 1"
    >
      <a-button type="primary" shape="circle" @click="showModal">
        <template #icon>
          <PlusOutlined />
        </template>
      </a-button>
    </a-affix>
  </div>
</template>
<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import CreateLive from "./CreateLive.vue";
import { defineComponent } from "vue";
import Tennis from "../../components/Tennis.vue";
export default defineComponent({
  data() {
    return {
      bottom: 10,
      visible: false,
      display: true,
    };
  },
  components: {
    Tennis,
    PlusOutlined,
    CreateLive,
  },
  watch: {
    closeModal() {
      this.closeModal();
    },
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    closeModal() {
      this.visible = false;
    },
    getConfig() {
      this.$store.dispatch("court/maxCourt");
    },
  },
  mounted() {},
  created() {
    this.getConfig();
  },
  computed: {
    diplayTennis() {
      return this.$store.state.tennis.filter((item) => {
        return item.status.toLowerCase().startsWith("live");
      });
    },
    maxCourt() {
      return this.$store.state.court.maxCourt;
    },
  },
});
</script>
<style scoped>
.right {
  margin-top: 10ch;
  text-align: right;
}
</style>
