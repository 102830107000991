<template>
  <div>
    <a-list
      :grid="{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 3, xl: 4, xxl: 5 }"
      :data-source="monitors"
    >
      <template #renderItem="{ item }">
        <a-list-item>
          <Monitor
            :name="item.name"
            :lastname="item.lastname"
            :code="item.code"
          />
        </a-list-item>
      </template>
    </a-list>
    <a-modal v-model:visible="visible" :title="this.$t('monitor.create')">
      <template #footer> </template>
      <CreateMonitor @closeModal="closeModal" />
    </a-modal>
    <a-affix :offset-bottom="bottom" class="right">
      <a-button type="primary" shape="circle" @click="showModal">
        <template #icon>
          <PlusOutlined />
        </template>
      </a-button>
    </a-affix>
  </div>
</template>
<script>
import Monitor from "../../components/Monitor.vue";
import CreateMonitor from "./CreateMonitor.vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      bottom: 10,
      matchs: [],
      visible: false,
    };
  },
  components: {
    Monitor,
    PlusOutlined,
    CreateMonitor,
  },
  watch: {
    closeModal() {
      this.closeModal();
    },
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    closeModal() {
      this.visible = false;
    },
  },
  created() {},
  computed: {
    monitors() {
      return this.$store.state.monitors;
    },
  },
});
</script>
<style scoped>
.right {
  margin-top: 10ch;
  text-align: right;
}
</style>
./player/CreateMonitor.vue
