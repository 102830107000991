<template>
  <div
    class="bg-image"
    :style="{
      backgroundImage:
        'url(' +
        (displaBackgroundyUrl !== undefined && displaBackgroundyUrl !== ''
          ? displaBackgroundyUrl
          : defaultImg) +
        ')',
      height: '100%',
    }"
  >
    <div v-if="tennis" class="score-container">
      <div
        v-if="tennis[0]"
        :style="{
          height: tennis[1] ? '50%' : '100%',
        }"
      >
        <Score
          :info="tennis[0]"
          :chronometer="getChronometer(tennis[0].position)"
          :showTitle="true"
        />
      </div>
      <div
        v-if="tennis[1]"
        :style="{
          height: tennis[0] ? '50%' : '100%',
        }"
      >
        <Score
          :info="tennis[1]"
          :chronometer="getChronometer(tennis[1].position)"
          :showTitle="true"
        />
      </div>
    </div>
    <div v-else>
      <div class="contImg">
        <img class="slogo" src="../../assets/home.png" alt="logo" />
      </div>
      <a-empty
        image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
        :image-style="{
          height: '100px',
        }"
      >
        <template #description>
          <span>
            {{ $t("tennis.no_matches") }}
          </span>
        </template>
      </a-empty>
    </div>
  </div>
</template>
<script>
import Score from "../../components/Score.vue";
import NoSleep from "@uriopass/nosleep.js";
var noSleep = new NoSleep();
export default {
  data() {
    return {
      defaultImg: "https://images7.alphacoders.com/734/734192.jpg",
    };
  },
  components: {
    Score,
  },
  methods: {
    getChronometer(position) {
      switch (position) {
        case 1:
          return this.chronometer1;
        case 2:
          return this.chronometer2;
        case 3:
          return this.chronometer3;
        case 4:
          return this.chronometer4;
        case 5:
          return this.chronometer5;
      }
    },
    wakeLockEnabled() {
      noSleep.enable();
    },
    wakeLockDisable() {
      noSleep.disable();
    },
  },
  computed: {
    tennis() {
      return this.$store.state.tennis.filter((tenn) =>
        tenn.display.toLowerCase().startsWith("available")
      );
    },
    chronometer1() {
      return this.$store.state.chronometer1.value;
    },
    chronometer2() {
      return this.$store.state.chronometer2.value;
    },
    chronometer3() {
      return this.$store.state.chronometer3.value;
    },
    chronometer4() {
      return this.$store.state.chronometer4.value;
    },
    chronometer5() {
      return this.$store.state.chronometer5.value;
    },
    chronometer6() {
      return this.$store.state.chronometer6.value;
    },
    displaBackgroundyUrl() {
      return this.$store.state.displaBackgroundyUrl;
    },
  },
  created() {
    this.wakeLockEnabled();
  },
  unmounted() {
    this.wakeLockDisable();
  },
};
</script>
<style>
.score-container {
  width: 100%;
  height: 100%;
}
.bg-image {
  width: 100%;
  background-repeat: unset;
  background-position: center;
  background-size: cover;
}
.ant-empty {
  font-size: 35px !important;
}
.contImg {
  width: 100%;
  text-align: center;
}
.slogo {
  width: 30%;
  height: 30%;
}
</style>
