<template>
  <div>
    <a-row type="flex" justify="center">
      <!-- players -->
      <a-col
        class="board-1"
        v-bind:class="{
          topb: num == 1,
          tbob: num == 1,
          bottomb: num == 2,
          leftb: image === undefined || image == '',
        }"
        :xs="{ span: 11 }"
        :sm="{ span: 13 }"
        :md="{ span: 9 }"
        :lg="{ span: 9 }"
      >
        <div>
          <div v-if="player.points.win">
            <a-badge>
              <template #count>
                <crown-outlined style="color: #efb810; font-size: 22px" />
              </template>
              <p v-bind:class="{ label: !double, labelMin: double }">
                {{ playerName() }}
              </p>
            </a-badge>
          </div>
          <div class="overlay" v-else>
            <p v-bind:class="{ label: !double, labelMin: double }">
              {{ playerName() }}
            </p>
            <img
              v-if="player.punch.value"
              src="../assets/lttball.png"
              class="ball"
              alt="ball"
            />
          </div>
        </div>
      </a-col>
      <a-col
        class="board-1"
        v-bind:class="{ topb: num == 1, tbob: num == 1, bottomb: num == 2 }"
        :xs="{ span: 1 }"
        :sm="{ span: 1 }"
        :md="{ span: 2 }"
        :lg="{ span: 2 }"
        v-if="player.points.prevSet1 !== -1"
      >
        <div class="overlay">
          <p class="label" :style="{ color: winPre1 ? '#ffff' : '#f5e1e140' }">
            {{ player.points.prevSet1 }}
          </p>
        </div>
      </a-col>
      <a-col
        class="board-1"
        v-bind:class="{ topb: num == 1, tbob: num == 1, bottomb: num == 2 }"
        :xs="{ span: 1 }"
        :sm="{ span: 1 }"
        :md="{ span: 2 }"
        :lg="{ span: 2 }"
        v-if="player.points.prevSet2 !== -1"
      >
        <div class="overlay">
          <p class="label" :style="{ color: winPre2 ? '#ffff' : '#f5e1e140' }">
            {{ player.points.prevSet2 }}
          </p>
        </div>
      </a-col>
      <a-col
        class="board-1"
        v-bind:class="{ topb: num == 1, tbob: num == 1, bottomb: num == 2 }"
        :xs="{ span: 1 }"
        :sm="{ span: 1 }"
        :md="{ span: 2 }"
        :lg="{ span: 2 }"
        v-if="player.points.prevSet3 !== -1"
      >
        <div class="overlay">
          <p class="label" :style="{ color: winPre3 ? '#ffff' : '#f5e1e140' }">
            {{ player.points.prevSet3 }}
          </p>
        </div>
      </a-col>
      <a-col
        class="board-1"
        v-bind:class="{ topb: num == 1, tbob: num == 1, bottomb: num == 2 }"
        :xs="{ span: 2 }"
        :sm="{ span: 2 }"
        :md="{ span: 3 }"
        :lg="{ span: 3 }"
        v-if="!end"
      >
        <div class="overlay">
          <p class="label">{{ player.points.set }}</p>
        </div>
      </a-col>
      <a-col
        class="board-2 rightb"
        v-bind:class="{ topb: num == 1, tbob: num == 1, bottomb: num == 2 }"
        :xs="{ span: 2 }"
        :sm="{ span: 2 }"
        :md="{ span: 3 }"
        :lg="{ span: 3 }"
      >
        <div class="overlay">
          <p class="label">
            {{ player.points.game == 41 ? "AD" : player.points.game }}
          </p>
        </div>
      </a-col>
      <!-- <a-col
        class="board-2 rightb"
        v-bind:class="{ topb: num == 1, bottomb: num == 2 }"
        :xs="{ span: 1 }"
        :sm="{ span: 1 }"
        :md="{ span: 1 }"
        :lg="{ span: 1 }"
      >
      </a-col> -->
    </a-row>
  </div>
</template>
<script>
import { CrownOutlined } from "@ant-design/icons-vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "RowScore",
  data() {
    return {};
  },
  props: {
    player: {},
    double: Boolean,
    winPre1: Boolean,
    winPre2: Boolean,
    winPre3: Boolean,
    num: Number,
    end: Boolean,
    image: String,
  },
  components: {
    CrownOutlined,
  },
  methods: {
    playerName() {
      const { members } = this.player;
      const { nameA, nameB } = members;

      let player = "";
      if (nameA) {
        player = nameA;
      }
      if (nameB) {
        player += " / " + nameB;
      }

      return player;
    },
  },
});
</script>

<style scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #f8f8f8;
  /* font-weight: bold; */
  /* center */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.board-1 {
  padding-top: 8px;
  padding-bottom: 0px;
  background: black;
}

.topb {
  /* border-top: 0.1px solid white; */
}

.tbob {
  /* border-bottom: 0.1px solid rgb(241, 242, 241); */
}

.bottomb {
  /* border-bottom: 0.1px solid white; */
}

.leftb {
  /* border-left: 0.1px solid white; */
}

.rightb {
  /* border-right: 0.1px solid white; */
}

.board-2 {
  padding-top: 10px;
  padding-bottom: 6px;
  background: #a3a3a3;
}

.label {
  color: white;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-right: 2px;
  font-size: var(--font-size, 1.3em);
}

.labelMin {
  color: white;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-right: 2px;
  font-size: var(--font-size, 1.4em);
}

.ball {
  /* width: 35%; */
  height: 35%;
}
</style>
