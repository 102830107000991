<template>
  <a-layout has-sider>
    <a-layout-sider
      :style="{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
      }"
    >
      <div class="logo" />
      <a-menu theme="dark" mode="inline">
        <a-sub-menu :title="this.$t('admin.matches')" key="sm">
          <router-link to="/admin/match">
            <a-menu-item key="1"> {{ $t("admin.normal") }} </a-menu-item>
          </router-link>
          <router-link to="/admin/challenge">
            <a-menu-item key="11"> {{ $t("admin.challenge") }} </a-menu-item>
          </router-link>
        </a-sub-menu>
        <router-link to="/admin/players">
          <a-menu-item key="10"> {{ $t("admin.players") }} </a-menu-item>
        </router-link>
        <router-link to="/admin/monitors">
          <a-menu-item key="5">
            <span class="nav-text">{{ $t("admin.monitors") }} </span>
          </a-menu-item>
        </router-link>
        <router-link to="/admin/courts">
          <a-menu-item key="2">
            <span class="nav-text">{{ $t("admin.court") }} </span>
          </a-menu-item>
        </router-link>
        <router-link to="/admin/games">
          <a-menu-item key="3">
            <span class="nav-text">{{ $t("admin.games") }} </span>
          </a-menu-item>
        </router-link>
        <router-link to="/admin/config/background">
          <a-menu-item key="4">
            <span class="nav-text">{{ $t("admin.game_background") }} </span>
          </a-menu-item>
        </router-link>
        <router-link to="/admin/live">
          <a-menu-item key="6">
            <span class="nav-text">{{ $t("admin.live") }} </span>
          </a-menu-item>
        </router-link>
        <router-link to="/admin/display">
          <a-menu-item key="7">
            <span class="nav-text">{{ $t("admin.display") }} </span>
          </a-menu-item>
        </router-link>
        <a-sub-menu :title="this.$t('admin.reports')" key="sr">
          <router-link to="/admin/report/match">
            <a-menu-item key="9">
              <span class="nav-text">{{ $t("report.normal") }} </span>
            </a-menu-item>
          </router-link>
          <router-link to="/admin/report/challenge">
            <a-menu-item key="12">
              <span class="nav-text">{{ $t("report.challenge") }} </span>
            </a-menu-item>
          </router-link>
        </a-sub-menu>
        <a-popconfirm
          :title="this.$t('admin.sure_finish')"
          :ok-text="this.$t('common.yes')"
          :cancel-tex="this.$t('common.no')"
          @confirm="confirm"
          @cancel="cancel"
        >
          <a-menu-item key="8">
            <span class="nav-text">{{ $t("admin.finish") }} </span>
          </a-menu-item>
        </a-popconfirm>
      </a-menu>
    </a-layout-sider>
    <a-layout :style="{ marginLeft: '200px' }">
      <a-layout-content :style="{ overflow: 'initial' }">
        <div
          :style="{ padding: '24px', background: '#fff', textAlign: 'center' }"
        >
          <router-view />
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
export default {
  methods: {
    confirm(e) {
      console.log("confirmar", e);
      let root = {};
      let finishGame = {};
      root.finishGame = finishGame;
      this.$store.dispatch("sendMessage", JSON.stringify(root));
    },
    cancel() {
      console.log("cancelar");
    },
  },
};
</script>
