import { createRouter, createWebHistory } from "vue-router";

import ScoreClient from "../views/client/ScoreClient.vue";
import ChallengeScore from "../views/client/ChallengeScore.vue";
import Score from "../views/monitor/ScoreMonitor.vue";
import Admin from "../views/Admin.vue";
import NotFound from "../views/error/NotFound.vue";
import Error from "../views/error/Error.vue";
import MatchList from "../views/matches/MatchList.vue";
import MonitorsList from "../views/monitor/MonitorsList.vue";
import Display from "../views/live/Display.vue";
import Live from "../views/live/Live.vue";
import CourtList from "../views/court/CourtList.vue";
import Login from "../views/Login.vue";
import GamesList from "../views/live/GamesList.vue";
import GameBackground from "../views/config/GameBackground.vue";
import GamesLive from "../views/client/GameLive.vue";
import GamesLiveCourt from "../views/client/GameLiveCourt.vue";
import MatchReport from "../views/report/MatchReport.vue";
import ChallengeReport from "../views/report/ChallengeReport.vue";
import PlayersList from "../views/player/PlayersList.vue";
import ChallengeList from "../views/matches/ChallengeList.vue";
import MonitorChallenge from "../views/monitor/MonitorChallenge.vue";
import store from "@/store";

const routes = [
  { path: "/", component: ScoreClient },
  { path: "/challenge", component: ChallengeScore },
  { path: "/monitor/tencode=:tencode", component: Score },
  { path: "/monitor/challenge", component: MonitorChallenge },
  { path: "/games", component: GamesLive },
  { path: "/games/court/courcode=:courcode", component: GamesLiveCourt },
  {
    path: "/admin",
    component: Admin,
    beforeEnter: (to, from, next) => {
      console.log(store.state.auth);
      if (!store.state.auth) {
        next("login");
      } else {
        next();
      }
    },
    children: [
      {
        path: "/admin/match",
        component: MatchList,
      },
      {
        path: "/admin/challenge",
        component: ChallengeList,
      },
      {
        path: "/admin/players",
        component: PlayersList,
      },
      {
        path: "/admin/monitors",
        component: MonitorsList,
      },
      {
        path: "/admin/courts",
        component: CourtList,
      },
      {
        path: "/admin/display",
        component: Display,
      },
      {
        path: "/admin/live",
        component: Live,
      },
      {
        path: "/admin/games",
        component: GamesList,
      },
      {
        path: "/admin/config/background",
        component: GameBackground,
      },
      {
        path: "/admin/report/match",
        component: MatchReport,
      },
      {
        path: "/admin/report/challenge",
        component: ChallengeReport,
      },
    ],
  },
  {
    path: "/login",
    component: Login,
    beforeEnter: (to, from, next) => {
      if (store.state.auth) {
        next("admin");
      } else {
        next();
      }
    },
  },
  { path: "/error", component: Error },
  { path: "/:pathMatch(.*)*", name: "not-found", component: NotFound },
  { path: "/:pathMatch(.*)", name: "bad-not-found", component: NotFound },
  //   { path: "/about", component: About },
];

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
});

router.beforeEach((to, from, next) => {
  document.title = "ATRIUM Sports scoreboard";
  next();
});

export default router;
