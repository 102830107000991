<template>
  <div class="cs-container">
    <a-row class="cs-score-container" type="flex" justify="center">
      <a-col
        :span="12"
        v-for="player in challenge.players"
        :key="player.position"
      >
        <div>
          <p class="cs-label">{{ player.members.nameA }}</p>
          <br />
          <p class="cs-point">
            {{ `${$t("challenge.score")} (${player.point})` }}
          </p>
          <div v-if="chronometer">
            <a-time-picker
              :value="dayjs(chronometer, 'H:m:s')"
              size="large"
              disabled
            />
          </div>
          <br />
          <br />
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import dayjs from "dayjs";
export default defineComponent({
  name: "ChallengeScore",
  props: {
    challenge: {},
    chronometer: String,
  },
  setup() {
    return {
      dayjs,
    };
  },
});
</script>
<style scoped>
.cs-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cs-score-container {
  width: 50%;
  height: 50%;
  /* border-style: solid;
  border-color: white; */
  /* background-color: black; */
}

.cs-label {
  color: white;
  margin-top: 10px;
  margin-bottom: 1px;
  margin-right: 2px;
  font-size: var(--font-size, 4em);
}

.cs-point {
  color: white;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-right: 2px;
  font-size: var(--font-size, 5em);
}
</style>
