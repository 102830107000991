<template>
  <div>
    <a-table :columns="columns" :data-source="matchesFinish" bordered>
      <template #bodyCell="{ column, text, record }">
        <template v-if="column.dataIndex === 'date'">
          <a>{{ text }}</a>
        </template>
        <template v-if="column.key === 'score'">
          <div>
            <p>{{ formatScore(record.score1, record.score2) }}</p>
          </div>
        </template>
      </template>
      <template #title> {{ $t("report.normal") }}</template>
      <!-- <template #footer>Footer</template> -->
    </a-table>
  </div>
</template>
<script>
import { defineComponent } from "vue";
const columns = [
  {
    title: "Fecha",
    dataIndex: "date",
  },
  {
    title: "Título de juego",
    dataIndex: "title",
  },
  {
    title: "Jugadores",
    children: [
      {
        title: "Jugador 1",
        dataIndex: "player1",
      },
      {
        title: "Jugador 2",
        dataIndex: "player2",
      },
    ],
  },
  {
    title: "Monitor",
    dataIndex: "monitor",
  },
  {
    title: "Score",
    key: "score",
  },
  {
    title: "Tiempo",
    dataIndex: "time",
  },
];
export default defineComponent({
  setup() {
    return {
      columns,
    };
  },
  components: {},
  methods: {
    getData() {
      this.$store.dispatch("report/getMatchFinish");
    },
    formatScore(score1, score2) {
      let score = "";
      if (score1.prevSet1 !== -1 && score2.prevSet1 !== -1) {
        score = score1.prevSet1 + "-" + score2.prevSet1;
      }

      if (score1.prevSet2 !== -1 && score2.prevSet2 !== -1) {
        score += ", " + score1.prevSet2 + "-" + score2.prevSet2;
      }

      if (score1.prevSet3 !== -1 && score2.prevSet3 !== -1) {
        score += ", " + score1.prevSet3 + "-" + score2.prevSet3;
      }

      return score;
    },
  },
  created() {
    this.getData();
  },
  computed: {
    matchesFinish() {
      return this.$store.state.report.matches;
    },
  },
});
</script>
