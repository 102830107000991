<template>
  <div>
    <a-list :grid="{ gutter: 16, column: 4 }" :data-source="displayMatch">
      <template #renderItem="{ item }">
        <a-list-item>
          <Tennis
            :code="item.code"
            :title="item.title"
            :players="item.players"
            :canEdit="item.status === 'CREATED'"
            :canCopy="false"
            :canRefresh="false"
          />
        </a-list-item>
      </template>
    </a-list>
    <a-modal v-model:visible="visible" :title="this.$t('tennis.display')">
      <template #footer> </template>
      <CreateDisplay @closeModal="closeModal" />
    </a-modal>
    <a-affix
      :offset-bottom="bottom"
      class="right"
      v-if="displayMatch.length <= 1"
    >
      <a-button type="primary" shape="circle" @click="showModal">
        <template #icon>
          <PlusOutlined />
        </template>
      </a-button>
    </a-affix>
  </div>
</template>
<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import CreateDisplay from "./CreateDisplay.vue";
import { defineComponent } from "vue";
import Tennis from "../../components/Tennis.vue";

export default defineComponent({
  data() {
    return {
      bottom: 10,
      visible: false,
      display: false,
    };
  },
  components: {
    Tennis,
    PlusOutlined,
    CreateDisplay,
  },
  watch: {
    closeModal() {
      this.getMatch();
      this.closeModal();
    },
  },
  methods: {
    getMatch() {
      this.$store.dispatch("match/display");
    },
    showModal() {
      this.visible = true;
    },
    closeModal() {
      this.visible = false;
    },
  },
  mounted() {},
  created() {
    this.getMatch();
  },
  computed: {
    displayMatch() {
      return this.$store.state.tennis.filter((tenn) =>
        tenn.display.toLowerCase().startsWith("available")
      );
    },
  },
});
</script>
<style scoped>
.right {
  margin-top: 10ch;
  text-align: right;
}
</style>
