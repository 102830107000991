<template>
  <div>
    <a-card hoverable style="width: 300px">
      <template #actions>
        <a-button shape="circle" @click="edit">
          <template #icon>
            <!-- <EditOutlined @click="edit" /> -->
          </template>
        </a-button>
      </template>
      <a-card-meta :title="name + ' ' + lastname">
        <template #avatar>
          <a-avatar :style="{ backgroundColor: randomColor() }">{{
            letter
          }}</a-avatar>
        </template>
      </a-card-meta>
    </a-card>
    <a-modal v-model:visible="visible" :title="this.$t('monitor.edit')">
      <template #footer> </template>
      <EditMonitor :id="code" @closeModal="closeModal" />
    </a-modal>
  </div>
</template>
<script>
// import { EditOutlined } from "@ant-design/icons-vue";
import { defineComponent } from "vue";
import EditMonitor from "./EditMonitor.vue";
export default defineComponent({
  name: "Monitor",
  data() {
    return {
      letter: "A",
      colorCache: {},
      visible: false,
    };
  },
  watch: {
    closeModal() {
      this.closeModal();
    },
  },
  components: {
    EditMonitor,
    // EditOutlined,
  },
  props: {
    code: Number,
    name: String,
    lastname: String,
  },
  methods: {
    randomColor(id) {
      const r = () => Math.floor(256 * Math.random());

      return (
        this.colorCache[id] ||
        (this.colorCache[id] = `rgb(${r()}, ${r()}, ${r()})`)
      );
    },
    edit() {
      this.visible = true;
    },
    closeModal() {
      this.visible = false;
    },
  },
  beforeMount() {
    if (this.name != undefined) {
      this.letter = this.name.substr(0, 1);
    }
  },
});
</script>
