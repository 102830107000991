<template>
  <a-form
    :model="formState"
    v-bind="layout"
    name="nest-messages"
    @finish="onFinish"
  >
    <a-form-item :name="['court', 'name']" :label="this.$t('court.name')">
      <a-input v-model:value="formState.court.name" />
    </a-form-item>
    <a-form-item :name="['court', 'color']" :label="this.$t('court.color')">
      <input
        type="color"
        v-model="formState.court.color"
        style="width: 100%; height: 100px"
      />
    </a-form-item>
    <a-form-item :wrapper-col="{ ...layout.wrapperCol, offset: 8 }">
      <a-button type="primary" html-type="submit">{{
        $t("common.update")
      }}</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "EditCourt",
  props: {
    id: Number,
  },
  data() {
    return {
      layout: {
        wrapperCol: { span: 16 },
      },
      formState: {
        court: {
          name: "",
          color: "#000000",
        },
      },
    };
  },
  methods: {
    onFinish(values) {
      let root = {};
      let court = {};
      court.action = "edit";
      values.court.code = this.id;
      court.data = values.court;
      root.courts = court;
      console.log(root);
      this.$store.dispatch("sendMessage", JSON.stringify(root));
      this.clear();
      this.$emit("closeModal");
    },
    clear() {
      this.formState.court = { name: "" };
    },
    load() {
      console.log(this.court);
      this.formState.court = {
        name: this.court.name,
        color: this.court.color ? this.court.color : "#000000",
      };
    },
  },
  mounted() {
    this.load();
  },
  computed: {
    court() {
      const elementIndex = this.$store.state.courts.findIndex(
        (obj) => obj.code == this.id
      );
      return this.$store.state.courts[elementIndex];
    },
  },
});
</script>
