<template>
  <a-form
    :model="formState"
    v-bind="layout"
    autocomplete="off"
    name="nest-messages"
    @finish="onFinish"
  >
    <a-form-item
      :name="['player', 'name']"
      :label="this.$t('common.name')"
      :rules="[{ required: true, message: this.$t('required.name') }]"
    >
      <a-input v-model:value="formState.player.name" />
    </a-form-item>
    <a-form-item
      :name="['player', 'lastname']"
      :label="this.$t('common.lastname')"
      :rules="[{ required: true, message: this.$t('required.lastname') }]"
    >
      <a-input v-model:value="formState.player.lastname" />
    </a-form-item>
    <a-form-item :wrapper-col="{ ...layout.wrapperCol, offset: 8 }">
      <a-button type="primary" html-type="submit">{{
        $t("common.update")
      }}</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
export default {
  name: "EditPlayer",
  props: {
    id: Number,
  },
  data() {
    return {
      layout: {
        wrapperCol: { span: 16 },
      },
      formState: {
        player: {
          name: "",
          lastname: "",
        },
      },
    };
  },
  methods: {
    onFinish(values) {
      console.log("Success:", values);
      values.player.id = this.id;
      console.log("json", JSON.stringify(values.player));
      this.$store.dispatch("player/save", values.player);
      this.$emit("closeModal");
    },
    load() {
      console.log(this.id);
      console.log("player", this.player);
      this.formState.player = {
        name: this.player.name,
        lastname: this.player.lastname,
      };
    },
    getPlayer(id) {
      this.$store.dispatch("player/getById", id);
    },
  },
  created() {
    this.getPlayer(this.id);
  },
  mounted() {
    this.load();
  },
  computed: {
    player() {
      return this.$store.state.player.item;
    },
  },
};
</script>
