<template>
  <div>
    <div>
      <div v-if="gameBackground !== ''" class="containerImg">
        <img alt="banner" :src="gameBackground" class="banner" />
      </div>
      <div v-if="maxCourt <= 3">
        <a-list :grid="{ column: 3 }" :data-source="courtsList">
          <template #renderItem="{ item }">
            <a-list-item>
              <CourtColumn :item="item" />
            </a-list-item>
          </template>
        </a-list>
      </div>
      <div v-else>
        <a-list :grid="{ column: 1 }" :data-source="courtsList">
          <template #renderItem="{ item }">
            <a-list-item>
              <CourtColumnV2 :item="item" />
            </a-list-item>
          </template>
        </a-list>
      </div>
    </div>
  </div>
</template>
<script>
import CourtColumn from "../../components/CourtColumnLive.vue";
import CourtColumnV2 from "../../components/CourtColumnLiveV2.vue";
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {};
  },
  components: {
    CourtColumn,
    CourtColumnV2,
  },
  methods: {
    getConfig() {
      this.$store.dispatch("court/maxCourt");
    },
  },
  created() {
    this.getConfig();
  },
  computed: {
    courtsList() {
      return this.$store.state.courts;
    },
    gameBackground() {
      return this.$store.state.bannerBackgroundUrl;
    },
    maxCourt() {
      return this.$store.state.court.maxCourt;
    },
  },
});
</script>
<style scoped>
.banner {
  width: 100%;
  object-fit: contain;
}
.containerImg {
  width: 100%;
  height: 25%;
}
</style>
