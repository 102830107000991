<template>
  <a-form
    :model="formState"
    v-bind="layout"
    name="nest-messages"
    @finish="onFinish"
  >
    <a-form-item :name="['update', 'tennis']" :label="this.$t('admin.matches')">
      <a-select
        v-model:value="formState.update.tennis"
        style="width: 250px"
        placeholder="Please select"
        :options="matches"
      ></a-select>
    </a-form-item>
    <a-form-item
      :name="['update', 'monitor']"
      :label="this.$t('admin.monitors')"
    >
      <a-select
        v-model:value="formState.update.monitor"
        style="width: 250px"
        placeholder="Please select"
        :options="displayMonitors"
      ></a-select>
    </a-form-item>
    <a-form-item :wrapper-col="{ ...layout.wrapperCol, offset: 8 }">
      <a-button type="primary" html-type="submit">{{
        $t("admin.display")
      }}</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
export default {
  data() {
    return {
      layout: {
        wrapperCol: { span: 16 },
      },
      formState: {
        update: {
          tennis: undefined,
          monitor: undefined,
        },
      },
      matches: [],
      monitors: [],
      maxTagCount: 3,
    };
  },
  methods: {
    onFinish(values) {
      console.log("values", values);
      let root = {};
      let tennis = {};
      tennis.action = "live";
      tennis.code = values.update.tennis;
      tennis.monitor = values.update.monitor;
      root.tennis = tennis;
      this.$store.dispatch("sendMessage", JSON.stringify(root));

      this.clear();
      this.$emit("closeModal");
    },
    clear() {
      this.formState.update = {
        tennis: undefined,
        monitor: undefined,
      };
      this.getMonitors();
    },
    load() {
      // console.log(this.diplayTennis);
      // console.log(this.displayMonitors);
      this.diplayTennis.forEach((element) => {
        this.matches.push({
          label: element.title,
          value: element.code,
          disabled: false,
        });
      });
    },
    getMonitors() {
      this.$store.dispatch("monitor/getAvailable");
    },
  },
  mounted() {
    this.load();
  },
  created() {
    this.getMonitors();
  },
  computed: {
    diplayTennis() {
      return this.$store.state.tennis.filter((item) => {
        return item.status.toLowerCase().startsWith("created");
      });
    },
    displayMonitors() {
      return this.$store.state.monitor.available;
      // return this.$store.state.monitors.filter((item) => {
      //   return item.status.toLowerCase().startsWith("available");
      // });
    },
  },
};
</script>
