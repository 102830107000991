<template>
  <div class="container">
    <div class="center">
      <h1 v-if="showTitle">{{ info.title }}</h1>
      <div id="row1">
        <RowScore
          :player="info.players.player1"
          :image="info.players.player1.pic"
          :num="1"
          :end="
            info.players.player1.points.win || info.players.player2.points.win
          "
          :winPre1="
            info.players.player1.points.prevSet1 >
            info.players.player2.points.prevSet1
          "
          :winPre2="
            info.players.player1.points.prevSet2 >
            info.players.player2.points.prevSet2
          "
          :winPre3="
            info.players.player1.points.prevSet3 >
            info.players.player2.points.prevSet3
          "
          :double="!info.types"
        />
      </div>
      <div class="row" id="row2">
        <RowScore
          :player="info.players.player2"
          :image="info.players.player2.pic"
          :num="2"
          :winPre1="
            info.players.player2.points.prevSet1 >
            info.players.player1.points.prevSet1
          "
          :winPre2="
            info.players.player2.points.prevSet2 >
            info.players.player1.points.prevSet2
          "
          :winPre3="
            info.players.player2.points.prevSet3 >
            info.players.player1.points.prevSet3
          "
          :end="
            info.players.player1.points.win || info.players.player2.points.win
          "
          :double="!info.types"
        />
      </div>
      <a-time-picker
        :value="dayjs(chronometer, 'H:m:s')"
        size="large"
        disabled
      />
    </div>
  </div>
</template>
<script>
import RowScore from "../components/RowScore.vue";
import dayjs from "dayjs";
import { defineComponent } from "vue";
export default defineComponent({
  name: "Score",
  props: {
    info: {},
    chronometer: String,
    showTitle: Boolean,
  },
  setup() {
    return {
      dayjs,
    };
  },
  components: {
    RowScore,
  },
  methods: {},
});
</script>

<style>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.center {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  width: 95%;
  /* width: auto; */
  /* background-color: black; */
}

.ant-picker.ant-picker-disabled {
  background: white !important;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  border-color: transparent !important;
  width: 100pt;
}

.ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: black !important; /* el reloj */
  font-size: 0.2in !important;
  font-weight: bold !important;
}

.ant-picker-input > input[disabled] {
  color: black !important; /* la letra */
  font-size: 0.2in !important;
  font-weight: bold;
}
</style>
