<template>
  <a-form
    :model="formState"
    v-bind="layout"
    autocomplete="off"
    name="nest-messages"
    @finish="onFinish"
  >
    <a-form-item
      :name="['challenge', 'types']"
      :label="this.$t('challenge.qty')"
    >
      <a-switch
        v-model:checked="formState.challenge.types"
        :checked-children="this.$t('challenge.singles')"
        :un-checked-children="this.$t('challenge.doubles')"
      />
    </a-form-item>

    <!-- players -->
    <a-form-item
      :name="['challenge', 'players', 'player1', 'id']"
      :label="this.$t('tennis.singles_player1')"
      :rules="[{ required: true, message: this.$t('required.player_name') }]"
    >
      <a-select
        v-model:value="formState.challenge.players.player1.id"
        style="width: 260px"
        placeholder="Please select"
        option-filter-prop="children"
        :filter-option="filterOption"
        show-search
        :options="displayPlayers"
      >
      </a-select>
    </a-form-item>

    <div v-if="!formState.challenge.types">
      <a-form-item
        :name="['challenge', 'players', 'player2', 'id']"
        :label="this.$t('tennis.singles_player2')"
        :rules="[{ required: true, message: this.$t('required.player_name') }]"
      >
        <a-select
          v-model:value="formState.challenge.players.player2.id"
          style="width: 260px"
          placeholder="Please select"
          option-filter-prop="children"
          :filter-option="filterOption"
          show-search
          :options="displayPlayers"
        >
        </a-select>
      </a-form-item>
    </div>

    <div v-if="formState.challenge.types">
      <a-form-item
        :name="['challenge', 'minutes']"
        :label="this.$t('challenge.time')"
        :rules="[{ required: true, message: this.$t('required.player_name') }]"
      >
        <a-input-number
          v-model:value="formState.challenge.minutes"
          :min="1"
          :max="10"
        />
      </a-form-item>
    </div>

    <a-form-item :wrapper-col="{ ...layout.wrapperCol, offset: 8 }">
      <a-button type="primary" html-type="submit">{{
        $t("common.create")
      }}</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import /*PlusOutlined,*/ /*FieldTimeOutlined*/ "@ant-design/icons-vue";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      layout: {
        wrapperCol: { span: 16 },
      },
      formState: {
        challenge: {
          types: true,
          minutes: 0,
          players: {
            player1: {
              id1: undefined,
            },
            player2: {
              id: undefined,
            },
          },
        },
      },
      open: false,
      newPlayerName: "",
      newPlayerLastname: "",
      value: "",
    };
  },
  components: {
    // PlusOutlined,
    // FieldTimeOutlined,
    // VNodes: (_, { attrs }) => {
    //   return attrs.vnodes;
    // },
  },
  methods: {
    getPlayers() {
      this.$store.dispatch("player/getCustom");
    },
    onFinish(values) {
      console.log("Success:", values);
      let root = {};
      let challenge = {};
      challenge.action = "created";
      challenge.data = values.challenge;
      root.challenge = challenge;
      console.log("json", JSON.stringify(root));
      this.$store.dispatch("sendMessage", JSON.stringify(root));
      this.clear();
      this.$emit("closeModal");
    },
    filterOption(input, option) {
      console.log(input, option);
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    click(e) {
      console.log("click");
      e.preventDefault();
    },
    addItem(e) {
      console.log(this.displayPlayers);
      e.preventDefault();
      this.displayPlayers.unshift({
        label: `${this.newPlayerName} ${this.newPlayerLastname}`,
        value: `${this.newPlayerName}@@${this.newPlayerLastname}`,
      });
      this.newPlayerName = "";
      this.newPlayerLastname = "";
    },
    handleSearch(val) {
      console.log(val);
      //return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      // this.displayPlayers = this.displayPlayers.filter((item)=>{
      //   console.log(item.label, val);
      //   console.log("  ", item.label.includes(val));
      //   return item.label.includes(val)
      // })
    },
    handleChangePlayer() {
      this.open = false;
    },
    clear() {
      this.formState.tennis = {
        title: "",
        backgroundUrl: "",
        types: true,
        format: "",
        advantage: false,
        players: {
          player1: {
            id1: undefined,
          },
          player2: {
            id1: undefined,
          },
        },
      };
    },
  },
  mounted() {},
  created() {
    this.getPlayers();
  },
  computed: {
    displayPlayers() {
      return this.$store.state.player.custom;
    },
  },
});
</script>
