import api from "../../services/api";

const state = () => ({
  available: [],
});

const mutations = {
  storeState(state, payload) {
    if (payload instanceof Array) {
      payload.forEach((element) => {
        state[element.key] = element.value;
      });
    } else {
      var { key, value } = payload;
      state[key] = value;
    }
  },
};

const actions = {
  getAvailable({ commit }) {
    console.log("getAvailable");
    api.get("/monitor/available").then((response) => {
      console.log(response.data);
      commit("storeState", {
        key: "available",
        value: response.data,
      });
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
