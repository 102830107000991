export default function createWebSocketPlugin(socket) {
  return (store) => {
    store.$socket = socket;
    // console.log(socket);
    socket.onmessage = function (event) {
      store.dispatch("receiveMessage", event.data);
    };
    socket.onerror = function (err) {
      // console.log(err);
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );
      store.dispatch("error", err);
    };
    socket.onclose = function (event) {
      console.log("Client notified socket has closed", event);
    };
  };
}
