<template>
  <a-form
    :model="formState"
    v-bind="layout"
    name="nest-messages"
    :validate-messages="validateMessages"
    @finish="onFinish"
  >
    <a-form-item :name="['update', 'tennis']" :label="this.$t('admin.matches')">
      <a-select
        v-model:value="formState.update.tennis"
        mode="multiple"
        style="width: 250px"
        placeholder="Please select"
        :max-tag-count="maxTagCount"
        @change="handleChange"
        :options="displayMatch"
      ></a-select>
    </a-form-item>
    <a-form-item :wrapper-col="{ ...layout.wrapperCol, offset: 8 }">
      <a-button type="primary" html-type="submit">{{
        $t("admin.display")
      }}</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
export default {
  name: "CreateDisplay",
  data() {
    return {
      layout: {
        wrapperCol: { span: 16 },
      },
      formState: {
        update: {
          tennis: [],
        },
      },
      validateMessages: {
        required: "${label} is required!",
        types: {
          email: "${label} is not a valid email!",
          number: "${label} is not a valid number!",
        },
        number: {
          range: "${label} must be between ${min} and ${max}",
        },
      },
      matches: [],
      monitors: [],
      maxTagCount: 3,
    };
  },
  methods: {
    getMatch() {
      this.$store.dispatch("match/getForDisplay");
    },
    onFinish(values) {
      let root = {};
      let tennnis = {};
      tennnis.action = "display";
      tennnis.data = values.update;
      root.tennis = tennnis;
      this.$store.dispatch("sendMessage", JSON.stringify(root));
      this.clear();
      this.$emit("closeModal");
    },
    handleChange(value) {
      console.log(value.length);
      if (value.length >= 2) {
        this.matches.forEach((element) => {
          console.log(element, value.includes(element.value));
          if (!value.includes(element.value)) {
            element.disabled = true;
          } else {
            element.disabled = false;
          }
        });
      } else {
        this.matches.forEach((element) => {
          element.disabled = false;
        });
      }
    },
    clear() {
      this.formState.update = {
        tennis: [],
      };
    },
    load() {
      // console.log(this.diplayTennis);
      // this.diplayTennis.forEach((element) => {
      //   this.matches.push({
      //     label: element.title,
      //     value: element.code,
      //     disabled: false,
      //   });
      // });
    },
  },
  mounted() {
    console.log("mounted");
    this.load();
  },
  created() {
    this.getMatch();
  },
  computed: {
    // diplayTennis() {
    //   return this.$store.state.tennis.filter((item) => {
    //     return (
    //       item.status.toLowerCase().startsWith("live") &&
    //       item.display.toLowerCase().startsWith("hide")
    //     );
    //   });
    // },
    displayMatch() {
      return this.$store.state.match.displayAvaible;
    },
  },
};
</script>
