<template>
  <div>
    <a-row type="flex" justify="center">
      <a-col
        class="set-a"
        :xs="{ span: 3 }"
        :sm="{ span: 3 }"
        :md="{ span: 3 }"
        :lg="{ span: 3 }"
        v-if="player.points.prevSet1 !== -1"
      >
        <div class="overlay">
          <p class="set-label">
            {{ player.points.prevSet1 }}
          </p>
        </div>
      </a-col>
      <a-col
        class="set-a"
        :xs="{ span: 3 }"
        :sm="{ span: 3 }"
        :md="{ span: 3 }"
        :lg="{ span: 3 }"
        v-if="player.points.prevSet2 !== -1"
      >
        <div class="overlay">
          <p class="set-label">
            {{ player.points.prevSet2 }}
          </p>
        </div>
      </a-col>
      <a-col
        class="set-a"
        :xs="{ span: 3 }"
        :sm="{ span: 3 }"
        :md="{ span: 3 }"
        :lg="{ span: 3 }"
        v-if="player.points.prevSet3 !== -1"
      >
        <div class="overlay">
          <p class="set-label">
            {{ player.points.prevSet3 }}
          </p>
        </div>
      </a-col>
      <a-col
        class="set-c"
        :xs="{ span: 3 }"
        :sm="{ span: 3 }"
        :md="{ span: 3 }"
        :lg="{ span: 3 }"
        v-if="!end"
      >
        <div class="overlay">
          <p class="set-label">
            {{ game == "n" ? player.points.set : player.points.game }}
          </p>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "MiniSet",
  data() {
    return {};
  },
  props: {
    player: {},
    end: Boolean,
    game: String,
  },
  components: {},
  methods: {},
});
</script>
<style scoped>
.set-a {
  padding-top: 10px;
  padding-bottom: 10px;
  background: #abb9cc;
  height: 15px;
}
.set-c {
  padding-top: 10px;
  padding-bottom: 10px;
  background: #d05765;
  height: 15px;
}
.set-label {
  color: white;
  margin-top: -5px;
  margin-bottom: 5px;
  margin-right: 1px;
  margin-left: 1px;
  /* font-size: 1.1vw; */
  font-size: var(--font-size, 0.45em);
}
</style>
