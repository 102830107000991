<template>
  <div class="log-container">
    <a-form
      :model="formState"
      name="basic"
      v-bind="layout"
      autocomplete="on"
      @finish="onFinish"
      @finishFailed="onFinishFailed"
    >
      <a-form-item
        :wrapper-col="{ ...layout.wrapperCol, offset: 4 }"
        :label="this.$t('login.username')"
        name="username"
        :rules="[{ required: true, message: this.$t('required.username') }]"
      >
        <a-input v-model:value="formState.username"
          ><template #prefix
            ><UserOutlined style="color: rgba(0, 0, 0, 0.25)"
          /></template>
        </a-input>
      </a-form-item>

      <a-form-item
        :wrapper-col="{ ...layout.wrapperCol, offset: 2 }"
        :label="this.$t('login.password')"
        name="password"
        :rules="[{ required: true, message: this.$t('required.password') }]"
      >
        <a-input-password v-model:value="formState.password"
          ><template #prefix
            ><LockOutlined style="color: rgba(0, 0, 0, 0.25)"
          /></template>
        </a-input-password>
      </a-form-item>

      <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
        <a-button type="primary" html-type="submit">{{
          $t("login.enter")
        }}</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { UserOutlined, LockOutlined } from "@ant-design/icons-vue";
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      layout: {
        wrapperCol: { span: 25 },
      },
      formState: {
        username: "",
        password: "",
      },
      matches: [],
      monitors: [],
      maxTagCount: 3,
    };
  },
  components: {
    UserOutlined,
    LockOutlined,
  },
  methods: {
    onFinish(values) {
      // console.log("values", values);
      this.$store.dispatch("login", values);
    },
    onFinishFailed(errorInfo) {
      console.log("Failed:", errorInfo);
    },
    clear() {
      this.formState = {
        username: "",
        password: "",
      };
    },
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },
  },
});
</script>
<style scoped>
.log-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
