<template>
  <div>
    <a-list :grid="{ column: 5 }" :data-source="courtsList">
      <template #renderItem="{ item }">
        <a-list-item>
          <CourtColumn :item="item" />
        </a-list-item>
      </template>
    </a-list>
    <a-modal v-model:visible="visible" :title="this.$t('game.create')">
      <template #footer> </template>
      <CreateGame @closeModal="closeModal" />
    </a-modal>
    <a-affix :offset-bottom="bottom" class="right">
      <a-button type="primary" shape="circle" @click="showModal">
        <template #icon>
          <PlusOutlined />
        </template>
      </a-button>
    </a-affix>
  </div>
</template>
<script>
import CourtColumn from "../../components/CourtColumn.vue";
import CreateGame from "./CreateGame.vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      bottom: 10,
      visible: false,
    };
  },
  components: {
    CourtColumn,
    PlusOutlined,
    CreateGame,
  },
  watch: {
    closeModal() {
      this.closeModal();
    },
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    closeModal() {
      this.visible = false;
    },
  },
  created() {},
  computed: {
    courtsList() {
      return this.$store.state.courts;
    },
  },
});
</script>
<style>
.right {
  margin-top: 10ch;
  text-align: right;
}
</style>
./live/CreateGame.vue
