<template>
  <div>
    <a-table :columns="columns" :data-source="matchesFinish" bordered>
      <template #bodyCell="{ column, text, record }">
        <template v-if="column.dataIndex === 'date'">
          <a>{{ text }}</a>
        </template>
        <template v-if="column.key === 'score'">
          <div>
            <p>{{ formatScore(record.score1, record.score2) }}</p>
          </div>
        </template>
      </template>
      <template #title> {{ $t("report.challenge") }}</template>
      <!-- <template #footer>Footer</template> -->
    </a-table>
  </div>
</template>
<script>
import { defineComponent } from "vue";
const columns = [
  {
    title: "Fecha",
    dataIndex: "date",
  },
  {
    title: "Jugador 1",
    children: [
      {
        title: "Nombre",
        dataIndex: "player1",
      },
      {
        title: "Puntaje",
        dataIndex: "point1",
      },
    ],
  },
  {
    title: "Jugador 2",
    children: [
      {
        title: "Nombre",
        dataIndex: "player2",
      },
      {
        title: "Puntaje",
        dataIndex: "point2",
      },
    ],
  },
];
export default defineComponent({
  setup() {
    return {
      columns,
    };
  },
  components: {},
  methods: {
    getData() {
      this.$store.dispatch("report/getChallengeFinish");
    },
  },
  created() {
    this.getData();
  },
  computed: {
    matchesFinish() {
      return this.$store.state.report.challenge;
    },
  },
});
</script>
