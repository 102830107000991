import api from "../../services/api";

const state = () => ({
  matches: [],
  challenge: [],
});

const mutations = {
  storeState(state, payload) {
    if (payload instanceof Array) {
      payload.forEach((element) => {
        state[element.key] = element.value;
      });
    } else {
      var { key, value } = payload;
      state[key] = value;
    }
  },
};

const actions = {
  getMatchFinish({ commit }) {
    console.log("get all players");
    api.get("/match/report/finish").then((response) => {
      commit("storeState", {
        key: "matches",
        value: response.data,
      });
    });
  },
  getChallengeFinish({ commit }) {
    console.log("get all players");
    api.get("/match/challenge/report/finish").then((response) => {
      commit("storeState", {
        key: "challenge",
        value: response.data,
      });
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
