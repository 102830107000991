import axios from "axios";
import router from "@/router";
// import { Message } from "element-ui";
import { notification } from "ant-design-vue";
import { loadStore, saveState } from "../utils/configureStorage";
// TODO: These url should be on Environment variables instead of hardcoded.
const api = axios.create({
  // baseURL: "https://atriumhn.com/api",
  // baseURL: "https://ltt-hn.com/api",
  baseURL: process.env.VUE_APP_CORE_SERVER,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token =
      loadStore("vuex").authenticate && loadStore("vuex").authenticate.session
        ? `Bearer ${loadStore("vuex").authenticate.session.token}`
        : null;
    if (token) {
      config.headers.common["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  (response) => {
    console.log("response ", response);
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          // TODO: Improve 400 error handler. Get error message
          notification["error"]({
            message: "Bad Request",
            description: "",
          });
          break;
        case 401:
          // TODO: Improve 401 error handler. Get error message and validate
          saveState("vuex", {});
          // Message.error("Session Expired");
          notification["error"]({
            message: "Session Expired",
            description: "",
          });
          router.replace({
            path: "/login",
          });
          break;
        case 403:
          router.replace({
            path: "/login",
          });
          break;
        case 404:
          notification["error"]({
            message: "Page does not exist",
            description: "",
          });

          break;
        case 502:
          setTimeout(() => {
            router.replace({
              path: "/login",
            });
          }, 1000);
      }
      return Promise.reject(error.response);
    } else {
      notification["error"]({
        message: "Error",
        description: error.message,
      });
      return Promise.reject(error);
    }
  }
);

export default api;
