<template>
  <div>
    <a-list
      :grid="{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 3, xl: 4, xxl: 5 }"
      :data-source="matchTennis"
    >
      <template #renderItem="{ item }">
        <a-list-item>
          <a-card hoverable style="width: 300px">
            <div>
              <div v-for="player in item.players" :key="player.code">
                <div>
                  <p>{{ player.members.nameA }}</p>
                </div>
              </div>
            </div>
          </a-card>
        </a-list-item>
      </template>
    </a-list>
    <a-modal v-model:visible="visible" :title="this.$t('challenge.create')">
      <template #footer> </template>
      <CreateMatchChallenge @closeModal="closeModal" />
    </a-modal>
    <a-affix :offset-bottom="bottom" class="right">
      <a-button type="primary" shape="circle" @click="showModal">
        <template #icon>
          <PlusOutlined />
        </template>
      </a-button>
    </a-affix>
  </div>
</template>
<script>
import CreateMatchChallenge from "./CreateChallenge.vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      bottom: 10,
      matchs: [],
      visible: false,
      display: false,
    };
  },
  components: {
    PlusOutlined,
    CreateMatchChallenge,
  },
  watch: {
    closeModal() {
      this.closeModal();
    },
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    closeModal() {
      this.visible = false;
    },
  },
  created() {},
  computed: {
    matchTennis() {
      return this.$store.state.challenges.filter((item) => {
        return !item.status.toLowerCase().startsWith("finish");
      });
    },
  },
});
</script>
<style scoped>
.right {
  margin-top: 10ch;
  text-align: right;
}
</style>
./CreateChallenge.vue
