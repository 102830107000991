export const loadStore = (key) => {
  try {
    let serializedState = localStorage.getItem(key);
    if (
      serializedState === null ||
      !serializedState ||
      serializedState === undefined
    ) {
      return {};
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return {};
  }
};

export const saveState = (key, value) => {
  try {
    const serializedState = JSON.stringify(value);
    localStorage.setItem(key, serializedState);
  } catch (error) {
    console.log("error -> saveState: ", value);
  }
};
