<template>
  <div>
    <a-col class="clmn">
      <div class="titleContainer">
        <p class="title">{{ item.name }}</p>
      </div>
      <div v-for="game in item.games" :key="game.id" class="div-carousel">
        <Game :id="game.id" />
      </div>
    </a-col>
  </div>
</template>
<script>
import Game from "../components/GameLive.vue";
export default {
  name: "CourtColumnLive",
  props: {
    item: {},
  },
  components: {
    Game,
  },
  methods: {},
};
</script>
<style scoped>
.clmn {
  width: 98%;
  text-align: center;
}

.title {
  margin: 0px;
  font-weight: bold;
  font-size: 1.2vw;
}

.titleContainer {
  background-color: #2f74b5;
  color: #000111;
  border-style: solid;
  border-color: rgb(0, 0, 0);
}

.div-carousel {
  border-bottom: 2px solid black;
  border-left: 2px solid black;
  border-right: 2px solid black;
}
</style>
