<template>
  <div>
    <div v-if="challenge">
      <a-button
        v-if="challenge.status == 'CREATED'"
        class="btnPlayer coral bright"
        @click="start"
      >
        <template #icon> </template>
        {{ $t("tennis.start") }}
      </a-button>
      <br />

      <a-row type="flex" justify="center">
        <a-col
          :span="12"
          v-for="(player, index) in challenge.players"
          :key="player.position"
        >
          <div class="player">
            <p class="label">{{ player.members.nameA }}</p>
            <br />
            <a-button
              class="btnPlayer green"
              @click="plusPoint(index)"
              :disabled="challenge.status !== 'LIVE'"
            >
              <template #icon> <PlusOutlined /></template>
              {{ `${$t("tennis.plus")} (${player.point})` }}
            </a-button>
            <br />
            <br />
            <a-button
              class="btnPlayer red"
              @click="subtract(index)"
              :disabled="challenge.status !== 'LIVE' || player.point < 1"
            >
              <template #icon> <MinusOutlined /> </template>
              {{ $t("tennis.subtract") }}
            </a-button>
          </div>
        </a-col>
      </a-row>

      <div class="bright">
        <a-popconfirm
          :title="this.$t('tennis.sure_finish')"
          :ok-text="this.$t('common.yes')"
          :cancel-tex="this.$t('common.no')"
          @confirm="stop"
          @cancel="cancel"
        >
          <a-button
            class="btnPlayer red right"
            :disabled="challenge.status !== 'LIVE'"
            >{{ $t("tennis.stop") }}</a-button
          >
        </a-popconfirm>
      </div>
    </div>
  </div>
</template>
<script>
import { PlusOutlined, MinusOutlined } from "@ant-design/icons-vue";
import { defineComponent } from "vue";
export default defineComponent({
  components: {
    PlusOutlined,
    MinusOutlined,
  },
  methods: {
    plusPoint(player) {
      let root = {};
      let challenge = {};
      challenge.action = "plus";
      challenge.code = this.challenge.code;
      challenge.player = player;
      root.challenge = challenge;
      console.log(root);
      this.$store.$socket.send(JSON.stringify(root));
    },
    subtract(player) {
      let root = {};
      let challenge = {};
      challenge.action = "subtract";
      challenge.code = this.challenge.code;
      challenge.player = player;
      root.challenge = challenge;
      console.log(root);
      this.$store.$socket.send(JSON.stringify(root));
    },
    start() {
      let root = {};
      let challenge = {};
      challenge.action = "start";
      challenge.code = this.challenge.code;
      root.challenge = challenge;
      console.log(root);
      this.$store.$socket.send(JSON.stringify(root));
    },
    stop() {
      let root = {};
      let challenge = {};
      challenge.action = "complete";
      challenge.code = this.challenge.code;
      root.challenge = challenge;
      this.$store.$socket.send(JSON.stringify(root));
      // this.redirectReload();
    },
    cancel() {
      console.log("cancelar");
    },
  },
  computed: {
    challenge() {
      return this.$store.state.challenges?.filter(
        (cll) =>
          cll.status.toLowerCase().startsWith("live") ||
          cll.status.toLowerCase().startsWith("created")
      )[0];
    },
  },
});
</script>

<style scoped>
.player {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.bright {
  text-align: right;
}

.label {
  color: black;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-right: 2px;
  font-size: var(--font-size, 1.2em);
}

.playerMargin {
  margin-right: 30px;
  margin-left: 30px;
}

.btnPlayer {
  width: 120px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: var(--font-size, 1.2em);
}

.btnPlayer.coral {
  background-color: coral;
  color: white;
}

.btnPlayer.red {
  background-color: #f44336;
  color: #f8f8f8;
}

.btnPlayer.red[disabled] {
  background-color: #d45252;
  color: #f8f8f8;
}

.btnPlayer.green {
  width: 180px;
  background-color: #8fce00;
  font-weight: bold;
  color: #f8f8f8;
}

.btnPlayer.green[disabled] {
  background-color: #d1e79c;
  color: #f8f8f8;
}
</style>
