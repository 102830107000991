<template>
  <a-result
    status="warning"
    title="There are some problems with your operation."
  >
    <template #extra>
      {{ this.isCoonected() }}
    </template>
  </a-result>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    isCoonected() {
      if (
        this.$store.$socket.readyState == 0 ||
        this.$store.$socket.readyState == 1
      ) {
        this.redirectReload();
      }
    },
    redirectReload() {
      this.$router.push({ path: "/games" }).then(() => {
        this.$router.go();
      });
    },
  },
};
</script>
