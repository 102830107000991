import api from "../../services/api";

const state = () => ({
  courtAvaible: [],
  displayAvaible: [],
  display: [],
});

const mutations = {
  storeState(state, payload) {
    if (payload instanceof Array) {
      payload.forEach((element) => {
        state[element.key] = element.value;
      });
    } else {
      var { key, value } = payload;
      state[key] = value;
    }
  },
};

const actions = {
  getForCourtAvaible({ commit }) {
    console.log("get all courts");
    api.get("/match/court/available").then((response) => {
      commit("storeState", {
        key: "courtAvaible",
        value: response.data,
      });
    });
  },
  getForDisplay({ commit }) {
    console.log("get all courts");
    api.get("/match/display/available").then((response) => {
      commit("storeState", {
        key: "displayAvaible",
        value: response.data,
      });
    });
  },
  display({ commit }) {
    console.log("get all courts");
    api.get("/match/display").then((response) => {
      commit("storeState", {
        key: "display",
        value: response.data,
      });
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
