<template>
  <a-form
    :model="formState"
    v-bind="layout"
    name="nest-messages"
    @finish="onFinish"
  >
    <a-form-item
      :name="['monitor', 'name']"
      :label="this.$t('common.name')"
      :rules="[{ required: true, message: this.$t('required.name') }]"
    >
      <a-input v-model:value="formState.monitor.name" />
    </a-form-item>
    <a-form-item
      :name="['monitor', 'lastname']"
      :label="this.$t('common.lastname')"
      :rules="[{ required: true, message: this.$t('required.lastname') }]"
    >
      <a-input v-model:value="formState.monitor.lastname" />
    </a-form-item>
    <a-form-item :wrapper-col="{ ...layout.wrapperCol, offset: 8 }">
      <a-button type="primary" html-type="submit">{{
        $t("common.create")
      }}</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
export default {
  data() {
    return {
      layout: {
        wrapperCol: { span: 16 },
      },
      formState: {
        monitor: {
          name: "",
          lastname: "",
        },
      },
    };
  },
  methods: {
    onFinish(values) {
      let root = {};
      let monitor = {};
      monitor.action = "created";
      monitor.data = values.monitor;
      root.monitors = monitor;
      this.$store.dispatch("sendMessage", JSON.stringify(root));
      this.clear();
      this.$emit("closeModal");
    },
    clear() {
      this.formState.monitor = { name: "" };
    },
  },
};
</script>
