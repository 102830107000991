import api from "../../services/api";

const state = () => ({
  data: [],
  item: {},
  custom: [],
});

const mutations = {
  storeState(state, payload) {
    if (payload instanceof Array) {
      payload.forEach((element) => {
        state[element.key] = element.value;
      });
    } else {
      var { key, value } = payload;
      state[key] = value;
    }
  },
};

const actions = {
  getAll({ commit }) {
    console.log("get all players");
    api.get("/player").then((response) => {
      commit("storeState", {
        key: "data",
        value: response.data,
      });
    });
  },
  getById({ commit }, id) {
    console.log("get all players");
    api.get("/player/" + id).then((response) => {
      commit("storeState", {
        key: "item",
        value: response.data,
      });
    });
  },
  getCustom({ commit }) {
    console.log("get all players");
    api.get("/player/custom").then((response) => {
      commit("storeState", {
        key: "custom",
        value: response.data,
      });
    });
  },
  save({ dispatch }, body) {
    console.log(body);
    api.post("/player", body).then((response) => {
      console.log(response);
      // if (response.data.answer) {
      dispatch("getAll");
      // } else {
      // Message.error(response.data.message);
      // }
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
