import api from "../../services/api";

const state = () => ({
  data: [],
  maxCourt: 0,
});

const mutations = {
  storeState(state, payload) {
    if (payload instanceof Array) {
      payload.forEach((element) => {
        state[element.key] = element.value;
      });
    } else {
      var { key, value } = payload;
      state[key] = value;
    }
  },
};

const actions = {
  getAll({ commit }) {
    console.log("get all courts");
    api.get("/court").then((response) => {
      commit("storeState", {
        key: "data",
        value: response.data,
      });
    });
  },
  maxCourt({ commit }) {
    console.log("get court max");
    api.get("/court/max").then((response) => {
      commit("storeState", {
        key: "maxCourt",
        value: response.data,
      });
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
