<template>
  <div v-if="code">
    <a-card hoverable style="width: 300px">
      <template #cover> </template>
      <template #actions>
        <p>{{ code }}</p>
        <edit-outlined v-if="canEdit" @click="edit" />
        <copy-outlined v-if="canCopy" @click="copy" />
        <cloud-sync-outlined v-if="canRefresh" @click="refresh" />
      </template>
      <a-card-meta :title="title" :description="vs()"> </a-card-meta>
    </a-card>
    <a-modal v-model:visible="visible" :title="this.$t('game.edit')">
      <template #footer> </template>
      <EditTennis :id="code" @closeModal="closeModal" />
    </a-modal>
  </div>
</template>

<script>
import {
  CopyOutlined,
  EditOutlined,
  CloudSyncOutlined,
} from "@ant-design/icons-vue";
import { defineComponent } from "vue";
import EditTennis from "./EditTennis.vue";
export default defineComponent({
  name: "Tennis",
  data() {
    return {
      visible: false,
      description: "",
    };
  },
  components: {
    EditTennis,
    CopyOutlined,
    EditOutlined,
    CloudSyncOutlined,
  },
  watch: {
    closeModal() {
      this.closeModal();
    },
  },
  props: {
    title: String,
    code: Number,
    players: {},
    display: Boolean,
    canEdit: Boolean,
    canCopy: Boolean,
    canRefresh: Boolean,
  },
  methods: {
    vs() {
      const { player1, player2 } = this.players;
      const members1 = player1.members;
      const members2 = player2.members;

      let p1 = this.playerName(members1);
      let p2 = this.playerName(members2);

      const versus = `${p1} vs ${p2}`;
      return versus;
    },
    playerName(members) {
      const { nameA, nameB } = members;

      let player = "";
      if (nameA) {
        player = nameA;
      }
      if (nameB) {
        player += " / " + nameB;
      }

      return player;
    },
    copy() {
      // Copy the text inside the text field
      navigator.clipboard.writeText(
        window.location.origin + "/monitor/tencode=" + this.code
      );
    },
    refresh() {
      let root = {};
      let tennis = {};
      tennis.action = "refresh";
      tennis.code = this.code;
      root.tennis = tennis;
      this.$store.dispatch("sendMessage", JSON.stringify(root));
    },
    edit() {
      this.visible = true;
    },
    closeModal() {
      this.visible = false;
    },
    deleted() {
      console.log("delete");
    },
  },
  beforeMount() {
    // this.description = this.vs();
  },
});
</script>
