<template>
  <div>
    <a-col class="clmn">
      <div class="titleContainer">
        <p class="title">{{ item.name }}</p>
      </div>
      <div
        v-for="(game, index) in item.games"
        :key="game.id"
        class="div-carousel"
      >
        <div class="btn-delete">
          <DeleteOutlined @click="rmMatch(item.code, index, game.id)" />
        </div>
        <Game :id="game.id" />
      </div>
    </a-col>
  </div>
</template>
<script>
import Game from "../components/Game.vue";
import { DeleteOutlined } from "@ant-design/icons-vue";
export default {
  name: "CourtColumn",
  props: {
    item: {},
  },
  components: {
    Game,
    DeleteOutlined,
  },
  methods: {
    rmMatch(court, index, matchId) {
      let root = {};
      let game = {};
      let data = {};
      data.court = court;
      data.index = index;
      data.tennis = matchId;
      game.action = "remove";
      game.data = data;
      root.games = game;
      console.log(root);
      this.$store.dispatch("sendMessage", JSON.stringify(root));
    },
  },
};
</script>
<style scoped>
.clmn {
  width: 98%;
}

.title {
  margin: 0px;
  font-weight: bold;
  font-size: 1.2vw;
}

.titleContainer {
  border-style: solid;
  border-color: rgb(0, 0, 0);
}

.div-carousel {
  border-bottom: 2px solid black;
  border-left: 2px solid black;
  border-right: 2px solid black;
}

.btn-delete {
  text-align: right;
  margin-right: 2px;
}
</style>
