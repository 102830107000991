<template>
  <div>
    <div v-if="court">
      <div>
        <a-col class="clmn">
          <div class="titleContainer">
            <p class="title">{{ court.name }}</p>
          </div>
          <div v-for="game in court.games" :key="game.id" class="div-carousel">
            <Game :id="game.id" />
          </div>
        </a-col>
      </div>
    </div>
    <div v-else>
      <a-result
        status="404"
        :title="this.$t('court.game_404')"
        :sub-title="this.$t('tennis.match_404')"
      >
        <template #extra> </template>
      </a-result>
    </div>
  </div>
</template>
<script>
import Game from "../../components/GameLive.vue";
export default {
  components: {
    Game,
  },
  methods: {},
  computed: {
    court() {
      const elementIndex = this.$store.state.courts.findIndex(
        (obj) => obj.code == this.courtId
      );
      return this.$store.state.courts[elementIndex];
    },
    courtId() {
      return this.$route.params.courcode;
    },
  },
};
</script>
<style scoped>
.clmn {
  width: 98%;
  text-align: center;
}

.title {
  margin: 0px;
  font-weight: bold;
  font-size: 1.2vw;
}

.titleContainer {
  background-color: #2f74b5;
  color: #000111;
  border-style: solid;
  border-color: rgb(0, 0, 0);
}

.div-carousel {
  border-bottom: 2px solid black;
  border-left: 2px solid black;
  border-right: 2px solid black;
}
</style>
