<template>
  <div>
    <div>
      <h2>{{ $t("background.games") }}</h2>
      <a-upload
        v-model:file-list="fileList"
        :action="ACTION"
        list-type="picture-card"
        :max-count="1"
        name="image"
        filename="image"
        accept="image/png, image/jpeg"
        @preview="handlePreview"
        @change="handleChangeBanner"
      >
        <div style="margin-top: 8px">{{ $t("common.upload_img") }}</div>
      </a-upload>
    </div>
    <div>
      <h2>{{ $t("background.display") }}</h2>
      <a-upload
        v-model:file-list="fileList2"
        :action="ACTION"
        list-type="picture-card"
        :max-count="1"
        name="image"
        filename="image"
        accept="image/png, image/jpeg"
        @preview="handlePreview"
        @change="handleChangeDisplay"
      >
        <div style="margin-top: 8px">{{ $t("common.upload_img") }}</div>
      </a-upload>
    </div>
    <div>
      <h2>{{ $t("background.display") }}</h2>
      <a-upload
        v-model:file-list="fileList3"
        :action="ACTION"
        list-type="picture-card"
        :max-count="1"
        name="image"
        filename="image"
        accept="image/png, image/jpeg"
        @preview="handlePreview"
        @change="handleChangeChallengeDisplay"
      >
        <div style="margin-top: 8px">{{ $t("common.upload_img") }}</div>
      </a-upload>
    </div>
    <a-modal
      :visible="previewVisible.value"
      :title="previewTitle.value"
      :footer="null"
      @cancel="handleCancel"
    >
      <img alt="background" style="width: 100%" :src="previewImage.value" />
    </a-modal>

    <a-button type="primary" html-type="submit" @click="update">{{
      $t("common.update")
    }}</a-button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      layout: {
        wrapperCol: { span: 16 },
      },
      fileList: [],
      fileList2: [],
      fileList3: [],
      previewImage: { value: "" },
      previewTitle: { value: "" },
      previewVisible: { value: false },
      respBannerUrl: "",
      respDisplayUrl: "",
      respChallengeDisplayUrl: "",
      ACTION: process.env.VUE_APP_IMG_SERVER + "/fileupload",
    };
  },
  methods: {
    update() {
      let root = {};
      let background = {};
      background.action = "created";
      background.bannerUrl = this.respBannerUrl;
      background.displayUrl = this.respDisplayUrl;
      background.displayChallengeUrl = this.respChallengeDisplayUrl;

      root.background = background;
      console.log("json", JSON.stringify(root));
      this.$store.dispatch("sendMessage", JSON.stringify(root));
    },
    handleCancel() {
      console.log("handleCancel");
      this.previewVisible.value = false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage.value = file.url || file.preview;
      this.previewVisible.value = true;
      this.previewTitle.value =
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1);
    },
    handleChangeBanner(file) {
      console.log("handleChangeBanner", file.file);
      if (file.file.response) {
        // Component will show file.url as link
        this.respBannerUrl = file.file.response.data.display_url;
        console.log(this.respBannerUrl);
      }
    },
    handleChangeDisplay(file) {
      console.log("handleChangeDisplay", file.file);
      if (file.file.response) {
        // Component will show file.url as link
        this.respDisplayUrl = file.file.response.data.display_url;
        console.log(this.respDisplayUrl);
      }
    },
    handleChangeChallengeDisplay(file) {
      console.log("handleChangeChallengeDisplay", file.file);
      if (file.file.response) {
        // Component will show file.url as link
        this.respChallengeDisplayUrl = file.file.response.data.display_url;
        console.log(this.respDisplayUrl);
      }
    },
    load() {
      let root = {
        uid: "-1",
        name: "image.png",
        status: "done",
        url: this.bannerBackgroundUrl,
      };
      this.fileList.push(root);
      let root2 = {
        uid: "-1",
        name: "image.png",
        status: "done",
        url: this.displaBackgroundyUrl,
      };
      this.fileList2.push(root2);
      let root3 = {
        uid: "-1",
        name: "image.png",
        status: "done",
        url: this.displayChallengeBackgroundUrl,
      };
      this.fileList3.push(root3);
    },
  },
  mounted() {
    this.load();
  },
  computed: {
    bannerBackgroundUrl() {
      return this.$store.state.bannerBackgroundUrl;
    },
    displaBackgroundyUrl() {
      return this.$store.state.displaBackgroundyUrl;
    },
    displayChallengeBackgroundUrl() {
      return this.$store.state.displayChallengeBackgroundUrl;
    },
  },
};
</script>
