<template>
  <div>
    <a-card hoverable style="width: 300px">
      <template #cover>
        <div v-if="picture">
          <a-avatar :size="64" :src="picture">{{ letter }}</a-avatar>
        </div>

        <div v-else>
          <a-avatar :size="64" :style="{ backgroundColor: randomColor() }">{{
            letter
          }}</a-avatar>
        </div>
      </template>
      <template #actions>
        <EditOutlined key="edit" @click="edit" />
      </template>
      <a-card-meta :title="name + ' ' + lastname"> </a-card-meta>
    </a-card>
    <a-modal v-model:visible="visible" :title="this.$t('player.edit')">
      <template #footer> </template>
      <EditPlayer :id="code" @closeModal="closeModal" />
    </a-modal>
  </div>
</template>
<script>
import { EditOutlined } from "@ant-design/icons-vue";
import { defineComponent } from "vue";
import EditPlayer from "./EditPlayer.vue";
export default defineComponent({
  name: "Player",
  data() {
    return {
      letter: "A",
      colorCache: {},
      visible: false,
    };
  },
  watch: {
    closeModal() {
      this.closeModal();
    },
  },
  components: {
    EditOutlined,
    EditPlayer,
  },
  props: {
    code: Number,
    name: String,
    lastname: String,
    picture: String,
  },
  methods: {
    randomColor(id) {
      const r = () => Math.floor(256 * Math.random());

      return (
        this.colorCache[id] ||
        (this.colorCache[id] = `rgb(${r()}, ${r()}, ${r()})`)
      );
    },
    edit() {
      this.visible = true;
    },
    closeModal() {
      this.visible = false;
    },
  },
  beforeMount() {
    if (this.name != undefined && this.lastname) {
      this.letter = this.name.substr(0, 1) + this.lastname.substr(0, 1);
    }
  },
});
</script>
