import router from "@/router";
import Vuex from "vuex";
import createWebSocketPlugin from "./websocketStorePlugin";
import socket from "../socket";
import court from "./modules/court";
import player from "./modules/player";
import match from "./modules/match";
import monitor from "./modules/monitor";
import report from "./modules/report";
import { notification } from "ant-design-vue";

const websocketPlugin = createWebSocketPlugin(socket);

export default new Vuex.Store({
  state: {
    tennis: [],
    monitors: [],
    courts: [],
    games: [],
    chronometer1: 0,
    chronometer2: 0,
    chronometer3: 0,
    bannerBackgroundUrl: "",
    displaBackgroundyUrl: "",
    auth: false,
  },
  modules: {
    court,
    player,
    match,
    report,
    monitor,
  },
  getters: {},
  mutations: {
    storeState(state, payload) {
      if (payload instanceof Array) {
        payload.forEach((element) => {
          state[element.key] = element.value;
        });
      } else {
        var { key, value } = payload;
        state[key] = value;
      }
    },
  },
  actions: {
    sendMessage(context, data) {
      this.$socket.send(data);
    },
    receiveMessage({ commit }, payload) {
      // console.log("receiveMessage", payload);
      const root = JSON.parse(payload);
      const {
        monitors,
        courts,
        games,
        tennis,
        challenges,
        chronometer0,
        chronometer1,
        chronometer2,
        chronometer3,
        chronometer4,
        chronometer5,
        bannerBackgroundUrl,
        displaBackgroundyUrl,
        displayChallengeBackgroundUrl,
      } = root;
      let webeceive = [];
      if (tennis) {
        webeceive.push({
          key: "tennis",
          value: tennis,
        });
      }
      if (challenges) {
        webeceive.push({
          key: "challenges",
          value: challenges,
        });
      }
      if (courts) {
        webeceive.push({
          key: "courts",
          value: courts,
        });
      }
      if (games) {
        webeceive.push({
          key: "games",
          value: games,
        });
      }
      if (monitors) {
        webeceive.push({
          key: "monitors",
          value: monitors,
        });
      }
      if (chronometer0) {
        webeceive.push({
          key: "chronometer0",
          value: chronometer0,
        });
      }
      if (chronometer1) {
        webeceive.push({
          key: "chronometer1",
          value: chronometer1,
        });
      }
      if (chronometer2) {
        webeceive.push({
          key: "chronometer2",
          value: chronometer2,
        });
      }
      if (chronometer3) {
        webeceive.push({
          key: "chronometer3",
          value: chronometer3,
        });
      }
      if (chronometer4) {
        webeceive.push({
          key: "chronometer4",
          value: chronometer4,
        });
      }
      if (chronometer5) {
        webeceive.push({
          key: "chronometer5",
          value: chronometer5,
        });
      }
      if (bannerBackgroundUrl) {
        webeceive.push({
          key: "bannerBackgroundUrl",
          value: bannerBackgroundUrl,
        });
      }
      if (displaBackgroundyUrl) {
        webeceive.push({
          key: "displaBackgroundyUrl",
          value: displaBackgroundyUrl,
        });
      }
      if (displayChallengeBackgroundUrl) {
        console.log(
          "displayChallengeBackgroundUrl",
          displayChallengeBackgroundUrl
        );
        webeceive.push({
          key: "displayChallengeBackgroundUrl",
          value: displayChallengeBackgroundUrl,
        });
      }
      // console.log("webeceive", webeceive);
      commit("storeState", webeceive);
    },
    error() {
      router.push("/error");
    },
    login({ commit }, payload) {
      if (payload.username === "admin" && payload.password === "LTT@2023ñ") {
        commit("storeState", {
          key: "auth",
          value: true,
        });
        router.push("/admin");
      } else {
        notification["error"]({
          message: "Login",
          description: "Error",
        });
      }
    },
  },
  plugins: [websocketPlugin],
});
