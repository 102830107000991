import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import socket from "./socket";

import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import i18n from "./i18n";

const app = createApp(App);
app.config.globalProperties.$socket = () => {
  socket;
};
app.use(store).use(i18n).use(router).use(Antd).mount("#app");
