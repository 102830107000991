<template>
  <a-form
    :model="formState"
    v-bind="layout"
    autocomplete="off"
    name="nest-messages"
    @finish="onFinish"
  >
    <a-form-item
      :name="['tennis', 'title']"
      :label="this.$t('tennis.title')"
      :rules="[{ required: true, message: this.$t('required.title') }]"
    >
      <a-input v-model:value="formState.tennis.title" />
    </a-form-item>

    <!-- player 1 -->
    <a-form-item
      :name="['tennis', 'players', 'player1', 'id1']"
      :label="
        formState.tennis.players.player1.members.nameB
          ? this.$t('tennis.doubles1_player1')
          : this.$t('tennis.singles_player1')
      "
      :rules="[{ required: true, message: this.$t('required.player_name') }]"
    >
      <a-select
        v-model:value="formState.tennis.players.player1.id1"
        placeholder="Please select"
        style="width: 200px"
        :options="displayPlayers"
      ></a-select>
    </a-form-item>

    <div v-if="formState.tennis.players.player1.members.nameB">
      <a-form-item
        :name="['tennis', 'players', 'player1', 'id2']"
        :label="this.$t('tennis.doubles1_player2')"
        :rules="[{ required: true, message: this.$t('required.player_name') }]"
      >
        <a-select
          v-model:value="formState.tennis.players.player1.id2"
          placeholder="Please select"
          style="width: 200px"
          :options="displayPlayers"
        ></a-select>
      </a-form-item>
    </div>

    <!-- player 2 -->
    <a-form-item
      :name="['tennis', 'players', 'player2', 'id1']"
      :label="
        formState.tennis.players.player2.members.nameB
          ? this.$t('tennis.doubles2_player1')
          : this.$t('tennis.singles_player2')
      "
      :rules="[{ required: true, message: this.$t('required.player_name') }]"
    >
      <a-select
        v-model:value="formState.tennis.players.player2.id1"
        placeholder="Please select"
        style="width: 200px"
        :options="displayPlayers"
      ></a-select>
    </a-form-item>

    <div v-if="formState.tennis.players.player2.members.nameB">
      <a-form-item
        :name="['tennis', 'players', 'player2', 'id2']"
        :label="this.$t('tennis.doubles2_player2')"
        :rules="[{ required: true, message: this.$t('required.player_name') }]"
      >
        <a-select
          v-model:value="formState.tennis.players.player2.id2"
          placeholder="Please select"
          style="width: 200px"
          :options="displayPlayers"
        ></a-select>
      </a-form-item>
    </div>
    <a-form-item :wrapper-col="{ ...layout.wrapperCol, offset: 8 }">
      <a-button type="primary" html-type="submit">{{
        $t("common.update")
      }}</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
export default {
  name: "EditTennis",
  props: {
    id: Number,
  },
  data() {
    return {
      layout: {
        wrapperCol: { span: 16 },
      },
      formState: {
        tennis: {
          title: "",
          players: {
            player1: {
              members: { nameA: "", nameB: "" },
            },
            player2: {
              members: { nameA: "", nameB: "" },
            },
          },
        },
      },
    };
  },
  methods: {
    onFinish(values) {
      console.log("Success:", values);
      let root = {};
      let tennis = {};
      tennis.action = "edit";
      tennis.code = this.tennis.code;
      tennis.data = values.tennis;
      root.tennis = tennis;
      console.log("json", JSON.stringify(root));
      this.$store.dispatch("sendMessage", JSON.stringify(root));
      this.$emit("closeModal");
    },
    load() {
      this.formState.tennis = {
        title: this.tennis.title,
        players: {
          player1: {
            members: {
              nameA: this.tennis.players.player1.members.nameA,
              nameB: this.tennis.players.player1.members.nameB
                ? this.tennis.players.player1.members.nameB
                : null,
            },
            id1: parseInt(this.tennis.players.player1.members.idA, 16),
            id2: parseInt(this.tennis.players.player1.members.idB, 16),
          },
          player2: {
            members: {
              nameA: this.tennis.players.player2.members.nameA,
              nameB: this.tennis.players.player2.members.nameB
                ? this.tennis.players.player1.members.nameB
                : null,
            },
            id1: parseInt(this.tennis.players.player2.members.idA, 16),
            id2: parseInt(this.tennis.players.player2.members.idB, 16),
          },
        },
      };
    },
    getPlayers() {
      this.$store.dispatch("player/getCustom");
    },
  },
  mounted() {
    this.load();
  },
  created() {
    this.getPlayers();
  },
  computed: {
    tennis() {
      const elementIndex = this.$store.state.tennis.findIndex(
        (obj) => obj.code == this.id
      );
      return this.$store.state.tennis[elementIndex];
    },
    displayPlayers() {
      return this.$store.state.player.custom;
    },
  },
};
</script>
