<template>
  <div class="container" @click="allMatch">
    <div class="crow">
      <a-row type="flex" justify="center">
        <a-col :span="12">
          <!-- live si hay -->
          <div v-if="findTennnis" class="clive">
            <div class="live">
              {{ $t("game.live") }}
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <!-- nombre de la cancha con su colorcito de fondo -->
          <div
            class="titleContainer"
            :style="{ backgroundColor: item.color ? item.color : '#2f74b5' }"
          >
            <p class="title">{{ item.name }}</p>
          </div>
        </a-col>
      </a-row>
    </div>
    <p></p>

    <br />
    <div>
      <div v-if="findTennnis">
        <!-- user  -->
        <div class="userContainer">
          <a-row>
            <a-col
              :xs="{ span: 8 }"
              :sm="{ span: 8 }"
              :md="{ span: 10 }"
              :lg="{ span: 10 }"
            >
              <br />
              <div class="overlay">
                <p class="name">
                  {{ findTennnis.players.player1.members.nameA }}
                </p>
                <img
                  v-if="
                    findTennnis.players.player1.punch.value &&
                    findTennnis.status === 'LIVE'
                  "
                  class="ball"
                  src="../assets/lttball_green.png"
                  alt="ball"
                />
              </div>
            </a-col>
            <a-col
              :xs="{ span: 16 }"
              :sm="{ span: 16 }"
              :md="{ span: 14 }"
              :lg="{ span: 14 }"
            >
              <MiniSet
                v-if="
                  findTennnis.status === 'LIVE' ||
                  findTennnis.status === 'FINISH'
                "
                :player="findTennnis.players.player1"
                :end="
                  findTennnis.players.player1.points.win ||
                  findTennnis.players.player2.points.win
                "
                :game="findTennnis.game.value"
            /></a-col>
          </a-row>
          <a-row>
            <a-col
              :xs="{ span: 8 }"
              :sm="{ span: 8 }"
              :md="{ span: 10 }"
              :lg="{ span: 10 }"
            >
              <br />
              <div class="overlay">
                <p class="name">
                  {{ findTennnis.players.player2.members.nameA }}
                </p>
                <img
                  v-if="
                    findTennnis.players.player2.punch.value &&
                    findTennnis.status === 'LIVE'
                  "
                  class="ball"
                  src="../assets/lttball_green.png"
                  alt="ball"
                />
              </div>
            </a-col>
            <a-col
              :xs="{ span: 16 }"
              :sm="{ span: 16 }"
              :md="{ span: 14 }"
              :lg="{ span: 14 }"
            >
              <MiniSet
                v-if="
                  findTennnis.status === 'LIVE' ||
                  findTennnis.status === 'FINISH'
                "
                :player="findTennnis.players.player2"
                :end="
                  findTennnis.players.player1.points.win ||
                  findTennnis.players.player2.points.win
                "
                :game="findTennnis.game.value"
            /></a-col>
          </a-row>
        </div>
        <!-- title match -->
        <div class="titleMatch">
          <p class="title">{{ findTennnis.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MiniSet from "../components/MiniSet.vue";
export default {
  name: "CourtColumnLiveV2",
  props: {
    item: {},
  },
  components: {
    MiniSet,
  },
  methods: {
    allMatch() {
      this.$router
        .push({ path: "/games/court/courcode=" + this.item.code })
        .then(() => {
          this.$router.go();
        });
    },
  },
  computed: {
    findTennnis() {
      let ids = [];
      this.item.games.map((g) => {
        ids.push(g.id);
      });

      const res = this.$store.state.tennis.filter((tenn) =>
        ids.includes(tenn.code)
      );

      const live = res.filter((tenn) =>
        tenn.status.toLowerCase().startsWith("live")
      );

      return live ? live[0] : null;
    },
  },
};
</script>
<style scoped>
.container {
  border: 2px solid black;
  border-radius: 5px;
  display: block;
  /* width: 100%; */
}

.crow {
  width: 100%;
}

.titleContainer {
  color: #ffffff;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.titleMatch {
  color: #ffffff;
  background-color: #5f555578;
  align-items: center;
  justify-content: center;
  width: 40%;
}

.title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: var(--font-size, 1em);
  padding: 1%;
}

.clive {
  align-items: center;
  justify-content: center;
  width: 100%;
  /* display: block; */
}

.live {
  width: 28%;
  background-color: #ee2912;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: var(--font-size, 1.2em);
  margin-left: 15px;
  margin-right: 10px;
  margin-top: 10px;
}

.userContainer {
  margin-top: 8px;
  margin-bottom: 8px;
}

.name {
  margin: 0px;
  /* font-size: 1.7vw; */
  font-size: var(--font-size, 1.1em);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* center */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.clmn {
  width: 98%;
  text-align: center;
}

.div-carousel {
  border-bottom: 2px solid black;
  border-left: 2px solid black;
  border-right: 2px solid black;
}

.ball {
  /* width: 35%; */
  height: 35%;
}
</style>
