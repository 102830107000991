<template>
  <div>
    <a-card hoverable style="width: 300px">
      <template #cover>
        <div>
          <a-avatar :size="64" :style="{ backgroundColor: getColor() }">
            {{ code }}
          </a-avatar>
        </div>
      </template>
      <template #actions>
        <EditOutlined key="edit" @click="edit" />
      </template>
      <a-card-meta :title="name"> </a-card-meta>
    </a-card>
    <a-modal v-model:visible="visible" :title="this.$t('court.edit')">
      <template #footer> </template>
      <EditCourt :id="code" @closeModal="closeModal" />
    </a-modal>
  </div>
</template>
<script>
import { EditOutlined } from "@ant-design/icons-vue";
import EditCourt from "./EditCourt.vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "Court",
  data() {
    return {
      visible: false,
      colorCache: {},
    };
  },
  props: {
    name: String,
    color: String,
    code: Number,
  },
  components: {
    EditOutlined,
    EditCourt,
  },
  methods: {
    getColor() {
      if (this.color) {
        return this.color;
      } else {
        return this.randomColor(this.code);
      }
    },
    randomColor(id) {
      const r = () => Math.floor(256 * Math.random());

      return (
        this.colorCache[id] ||
        (this.colorCache[id] = `rgb(${r()}, ${r()}, ${r()})`)
      );
    },
    edit() {
      this.visible = true;
    },
    closeModal() {
      this.visible = false;
    },
  },
});
</script>
<style>
.icon {
  width: 35%;
  height: 35%;
}
</style>
