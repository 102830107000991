<template>
  <div v-if="findTennnis" class="itemGame">
    <p class="title">{{ findTennnis.title }}</p>
    <p class="name" v-if="exist(findTennnis.players.player1.members.nameA)">
      {{ findTennnis.players.player1.members.nameA }}
    </p>
    <p class="name">VS</p>
    <p class="name" v-if="exist(findTennnis.players.player1.members.nameA)">
      {{ findTennnis.players.player2.members.nameA }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Game",
  props: {
    id: Number,
  },
  data() {
    return {};
  },
  computed: {
    findTennnis() {
      const game = this.$store.state.tennis.findIndex(
        (obj) => obj.code == this.id
      );
      return this.$store.state.tennis[game];
    },
  },
  mounted() {},
  methods: {
    exist(value) {
      return value != "";
    },
  },
};
</script>
<style scoped>
.itemGame {
  padding: 10px;
  /* border-style: solid; */
}
.title {
  margin: 0px;
  font-weight: bold;
  font-size: 1vw;
}
.name {
  margin: 0px;
  /* background-color: aqua; */
}
</style>
