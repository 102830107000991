<template>
  <div class="container">
    <div v-if="tennis">
      <!-- siempre visible -->
      <div class="bright" v-if="!someWin()">
        <a-popconfirm
          :title="this.$t('tennis.sure_finish')"
          :ok-text="this.$t('common.yes')"
          :cancel-tex="this.$t('common.no')"
          @confirm="stop"
          @cancel="cancel"
        >
          <a-button class="btnPlayer red right" :disabled="!ifPuch()">{{
            $t("tennis.stop")
          }}</a-button>
        </a-popconfirm>
      </div>
      <h2 class="title">{{ tennis.title }}</h2>
      <br />
      <!-- si alguien gano -->
      <div v-if="someWin()">
        <div class="bright">
          <a-button class="btnPlayer red right" @click="stop">{{
            $t("tennis.stop")
          }}</a-button>
        </div>
        <br />
        <div>
          <a-badge>
            <template #count>
              <crown-outlined style="color: #efb810; font-size: 22px" />
            </template>
            <h2 class="win">
              {{
                `${$t("tennis.win")} ${
                  tennis.players.player1.points.win ? player1() : player2()
                }`
              }}
            </h2>
          </a-badge>
        </div>
      </div>
      <!-- si nadie ha ganado verificar si alguien esta sacando -->
      <div v-else>
        <div v-if="ifPuch()">
          <div>
            <Score
              :info="tennis"
              :chronometer="getChronometer(tennis.position)"
              :showTitle="false"
            />
          </div>
          <a-button class="btnPoints" @click="newPoint(1)" html-type="submit">
            {{ `${$t("tennis.point")} ${player1()}` }}
          </a-button>
          <a-button class="btnPoints" @click="newPoint(2)">
            {{ `${$t("tennis.point")} ${player2()}` }}
          </a-button>
          <br />
          <a-button
            class="btnPoints"
            @click="back()"
            :disabled="!tennis.canBack"
          >
            <template #icon>
              <UndoOutlined />
            </template>
            {{ $t("tennis.back") }}
          </a-button>
        </div>
        <!-- how -->
        <div v-else>
          <span class="question">{{ $t("tennis.h_puch") }}</span>
          <br />
          <a-button
            v-if="!preStart"
            class="btnPoints"
            @click="selectPlayer(1)"
            html-type="submit"
            >{{ player1() }}</a-button
          >
          <a-button
            v-if="!preStart"
            class="btnPoints"
            @click="selectPlayer(2)"
            html-type="submit"
            >{{ player2() }}</a-button
          >
        </div>
      </div>
    </div>
    <div v-else>
      <a-result
        status="404"
        :title="this.$t('admin.matches')"
        :sub-title="this.$t('tennis.match_404')"
      >
        <template #extra> </template>
      </a-result>
    </div>
  </div>
</template>
<script>
import Score from "../../components/ScoreMonitor.vue";
import {
  CrownOutlined /*, PauseOutlined*/,
  UndoOutlined,
} from "@ant-design/icons-vue";
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      preStart: false,
    };
  },
  components: {
    Score,
    CrownOutlined,
    UndoOutlined,
  },
  methods: {
    start() {
      let root = {};
      let tennis = {};
      tennis.action = "start";
      tennis.code = this.tennis.code;
      root.tennis = tennis;
      this.$store.$socket.send(JSON.stringify(root));
    },
    stop() {
      console.log("stop chronometer...");
      let root = {};
      let tennis = {};
      tennis.action = "stop";
      tennis.code = this.tennis.code;
      root.tennis = tennis;
      this.$store.$socket.send(JSON.stringify(root));
      this.redirectReload();
    },
    cancel() {
      console.log("cancelar");
    },
    redirectReload() {
      this.$router.push({ path: "/games" }).then(() => {
        this.$router.go();
      });
    },
    player1() {
      const { player1 } = this.tennis.players;
      const members1 = player1.members;
      const { nameA, nameB } = members1;

      let p1 = "";
      if (nameA) {
        p1 = nameA;
      }
      if (nameB) {
        p1 += " / " + nameB;
      }
      return p1;
    },
    player2() {
      const { player2 } = this.tennis.players;
      const members2 = player2.members;
      const { nameA, nameB } = members2;
      let p2 = "";
      if (nameA) {
        p2 = nameA;
      }
      if (nameB) {
        p2 += " / " + nameB;
      }
      return p2;
    },
    ifPuch() {
      const { player1, player2 } = this.tennis.players;
      const punch = player1.punch.value || player2.punch.value;
      this.preStart = punch;
      return punch;
    },
    someWin() {
      return (
        this.tennis.players.player1.points.win ||
        this.tennis.players.player2.points.win
      );
    },
    newPoint(number) {
      console.log("newPoint");
      let root = {};
      let tennis = {};
      tennis.action = "game";
      tennis.code = this.tennis.code;
      tennis.player = `player${number}`;
      root.tennis = tennis;
      // this.$store.dispatch("sendMessage", JSON.stringify(root));
      this.$store.$socket.send(JSON.stringify(root));
    },
    back() {
      console.log("back");
      let root = {};
      let tennis = {};
      tennis.action = "back";
      tennis.code = this.tennis.code;
      root.tennis = tennis;
      // this.$store.dispatch("sendMessage", JSON.stringify(root));
      this.$store.$socket.send(JSON.stringify(root));
    },
    selectPlayer(number) {
      console.log("selectPlayer ", number);
      let root = {};
      let tennis = {};
      tennis.action = "select";
      tennis.code = this.tennis.code;
      tennis.player = `player${number}`;
      root.tennis = tennis;
      this.$store.$socket.send(JSON.stringify(root));
      this.preStart = true;
    },
    getChronometer(position) {
      switch (position) {
        case 1:
          return this.chronometer1;
        case 2:
          return this.chronometer2;
        case 3:
          return this.chronometer3;
        case 4:
          return this.chronometer4;
        case 5:
          return this.chronometer5;
      }
    },
  },
  computed: {
    tennis() {
      console.log(this.match);
      console.log(this.$store.state.tennis);
      const elementIndex = this.$store.state.tennis.findIndex(
        (obj) => obj.code == this.match
      );
      return this.$store.state.tennis[elementIndex];
    },
    chronometer1() {
      return this.$store.state.chronometer1.value;
    },
    chronometer2() {
      return this.$store.state.chronometer2.value;
    },
    chronometer3() {
      return this.$store.state.chronometer3.value;
    },
    chronometer4() {
      return this.$store.state.chronometer4.value;
    },
    chronometer5() {
      return this.$store.state.chronometer5.value;
    },
    match() {
      return this.$route.params.tencode;
    },
  },
});
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: black;
}

.title {
  font-size: xx-large;
  color: #f8f8f8;
}

.question {
  color: #f8f8f8;
  font-size: 18pt;
}

.btnPlayer {
  width: 100px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.btnPlayer.red {
  background-color: #f44336;
  color: #f8f8f8;
}

.btnPlayer.red[disabled] {
  background-color: #d45252;
  color: #f8f8f8;
}

.btnPlayer.green {
  background-color: #8fce00;
  color: #f8f8f8;
}

.btnPlayer.green[disabled] {
  background-color: #d1e79c;
  color: #f8f8f8;
}

.btnPoints {
  min-height: 70px;
  margin: 20px;
}

.bg-image {
  width: 100%;
  height: 100%;
  /* background-color: #0000; */
  background: #0000;
  /* background-image: url("https://images7.alphacoders.com/734/734192.jpg"); */
  background-repeat: unset;
  background-position: center;
  background-size: cover;
}

.ant-result-subtitle {
  color: #fafafa !important;
}

.win {
  color: white;
}

.bright {
  text-align: right;
}
</style>
