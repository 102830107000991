export default {
  "tennis": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear partido"])},
    "display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione los partidos a visualizar (max 2)"])},
    "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona el partido & monitor a iniciar"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título"])},
    "background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "singles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singles"])},
    "doubles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doubles"])},
    "monitor_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione un monitor"])},
    "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato de juego"])},
    "format1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 set a 8 games"])},
    "format2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 sets a 4 games"])},
    "format3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 sets a 6 games"])},
    "advantage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventaja"])},
    "ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AD"])},
    "without_ad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIN AD"])},
    "apply_super_tiebreak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplica a super tiebrek"])},
    "super_tiebreak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super tiebrek"])},
    "without_super_tiebreak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIN Super tiebrek"])},
    "pic_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto 1"])},
    "pic_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto 2"])},
    "pic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto"])},
    "singles_player1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jugador 1"])},
    "singles_player2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jugador 2"])},
    "doubles1_player1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pareja 1, Jugador 1"])},
    "doubles1_player2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pareja 1, Jugador 2"])},
    "doubles2_player1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pareja 2, Jugador 1"])},
    "doubles2_player2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pareja 2, Jugador 2"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicar"])},
    "stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detener"])},
    "plus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumar"])},
    "subtract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restar"])},
    "sure_finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de finalizar el partido?"])},
    "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punto para "])},
    "win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juego terminado, ganador: "])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "no_matches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay partidos disponibles"])},
    "match_404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No encontramos el partido que esta buscando"])},
    "h_puch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione quien inicia sacando"])}
  },
  "challenge": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear challenge"])},
    "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jugador"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo (minutos)"])},
    "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de jugadores"])},
    "plus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumar"])},
    "subtract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restar"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "singles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Jugador"])},
    "doubles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Jugador"])}
  },
  "admin": {
    "matches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partidos"])},
    "normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
    "challenge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Challenge"])},
    "players": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jugadores"])},
    "monitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitores"])},
    "display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display"])},
    "court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canchas"])},
    "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partidos en vivo"])},
    "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juegos"])},
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reportes"])},
    "game_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporte de Juegos"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar programación"])},
    "sure_finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de finalizar la programación?, Esto borrará todos los partidos"])},
    "game_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fondos de Juego"])},
    "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar único partido"])},
    "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar 2 partidos en pantalla divida"])}
  },
  "monitor": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear monitor"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar monitor"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])}
  },
  "court": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cancha"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar cancha"])},
    "game_404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No encontramos la cancha que esta buscando"])}
  },
  "player": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear jugador"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar jugador"])}
  },
  "login": {
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar"])}
  },
  "game": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear Juego"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar Juego"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título"])},
    "courts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canchas"])},
    "tennis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partidos"])},
    "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live °"])},
    "court_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione una cancha"])},
    "tennis_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione un partido"])},
    "singles_player1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jugador 1"])}
  },
  "background": {
    "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner de programación"])},
    "display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fondo del display"])}
  },
  "common": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
    "cancelar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "upload_img": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir imagen"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
  },
  "required": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El título es requerido"])},
    "monitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El monitor es requerido"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre es requerido"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El apellido es requerido"])},
    "player_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre del jugador es requerido"])},
    "player1_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre del jugador 1 es requerido"])},
    "player2_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre del jugador 2 es requerido"])},
    "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato requerido"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario requerido"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña requerida"])},
    "court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cancha es requerida"])},
    "game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El partido es requerido"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tiempo es requerido"])}
  },
  "report": {
    "normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juegos"])},
    "challenge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Challenge"])},
    "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporte mensual"])}
  }
}