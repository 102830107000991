<template>
  <a-form
    :model="formState"
    v-bind="layout"
    autocomplete="off"
    name="nest-messages"
    @finish="onFinish"
  >
    <a-form-item
      :name="['court']"
      :label="this.$t('game.courts')"
      :rules="[{ required: true, message: this.$t('required.court') }]"
    >
      <a-select
        v-model:value="formState.court"
        :placeholder="this.$t('game.court_placeholder')"
        style="width: 200px"
        :options="courts"
      ></a-select>
    </a-form-item>
    <a-form-item
      :name="['tennis']"
      :label="this.$t('game.tennis')"
      :rules="[{ required: true, message: this.$t('required.game') }]"
    >
      <a-select
        v-model:value="formState.tennis"
        :placeholder="this.$t('game.tennis_placeholder')"
        style="width: 200px"
        :options="displayMatch"
      ></a-select>
    </a-form-item>
    <a-form-item :wrapper-col="{ ...layout.wrapperCol, offset: 8 }">
      <a-button type="primary" html-type="submit">{{
        $t("common.create")
      }}</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
export default {
  data() {
    return {
      layout: {
        wrapperCol: { span: 16 },
      },
      formState: {
        court: undefined,
        tennis: undefined,
      },
      courts: [],
    };
  },
  methods: {
    getMatch() {
      this.$store.dispatch("match/getForCourtAvaible");
    },
    onFinish(values) {
      let root = {};
      let game = {};
      game.action = "created";
      game.data = values;
      root.games = game;
      this.$store.dispatch("sendMessage", JSON.stringify(root));
      this.clear();
      this.$emit("closeModal");
    },
    load() {
      this.displayCourts.forEach((element) => {
        this.courts.push({ label: element.name, value: element.code });
      });
    },
    clear() {
      this.formState = {
        court: undefined,
        tennis: undefined,
      };
    },
  },
  mounted() {
    this.load();
  },
  created() {
    this.getMatch();
  },
  computed: {
    displayCourts() {
      return this.$store.state.courts;
    },
    displayMatch() {
      return this.$store.state.match.courtAvaible;
    },
  },
};
</script>
