<template>
  <a-form
    :model="formState"
    v-bind="layout"
    name="nest-messages"
    @finish="onFinish"
  >
    <a-form-item :label="this.$t('tennis.pic')">
      <a-upload
        :action="ACTION"
        list-type="picture-card"
        :max-count="1"
        name="image"
        filename="image"
        accept="image/png, image/jpeg"
        @preview="handlePreview"
        @change="handleChangePlayer"
      >
        <div style="margin-top: 8px">{{ $t("common.upload_img") }}</div>
      </a-upload>
      <a-modal
        :visible="previewVisible.value"
        :title="previewTitle.value"
        :footer="null"
        @cancel="handleCancel"
      >
        <img alt="pic_1" style="width: 100%" :src="previewImage.value" />
      </a-modal>
    </a-form-item>
    <a-form-item
      :name="['player', 'name']"
      :label="this.$t('common.name')"
      :rules="[{ required: true, message: this.$t('required.name') }]"
    >
      <a-input v-model:value="formState.player.name" />
    </a-form-item>
    <a-form-item
      :name="['player', 'lastname']"
      :label="this.$t('common.lastname')"
      :rules="[{ required: true, message: this.$t('required.lastname') }]"
    >
      <a-input v-model:value="formState.player.lastname" />
    </a-form-item>
    <a-form-item :wrapper-col="{ ...layout.wrapperCol, offset: 8 }">
      <a-button type="primary" html-type="submit">{{
        $t("common.create")
      }}</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
export default {
  data() {
    return {
      layout: {
        wrapperCol: { span: 16 },
      },
      formState: {
        player: {
          name: "",
          lastname: "",
          picture: "",
        },
      },
      previewImage: { value: "" },
      previewTitle: { value: "" },
      previewVisible: { value: false },
      respPlayerUrl: "",
      ACTION: process.env.VUE_APP_IMG_SERVER + "/fileupload",
    };
  },
  methods: {
    onFinish(values) {
      values.player.picture = this.respPlayerUrl;
      this.$store.dispatch("player/save", values.player);
      this.clear();
      this.$emit("closeModal");
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage.value = file.url || file.preview;
      this.previewVisible.value = true;
      this.previewTitle.value =
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1);
    },
    handleChangePlayer(file) {
      if (file.file.response) {
        console.log("handleChangePlayer", file.file.response);
        // Component will show file.url as link
        this.respPlayerUrl = file.file.response.data.display_url;

        console.log(this.respPlayerUrl);
      }
    },
    handleCancel() {
      console.log("handleCancel");
      this.previewVisible.value = false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    clear() {
      this.formState.player = { name: "", lastname: "" };
    },
  },
};
</script>
