<template>
  <div
    class="bg-image"
    :style="{
      backgroundImage:
        'url(' +
        (displayChallengeBackgroundUrl !== undefined &&
        displayChallengeBackgroundUrl !== ''
          ? displayChallengeBackgroundUrl
          : defaultImg) +
        ')',
      height: '100%',
    }"
  >
    <div v-if="challenge" class="score-container">
      <ChallengeScore :challenge="challenge" :chronometer="chronometer" />
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import ChallengeScore from "../../components/ChallengeScore.vue";
export default defineComponent({
  data() {
    return {
      defaultImg: "https://images7.alphacoders.com/734/734192.jpg",
    };
  },
  components: {
    ChallengeScore,
  },
  computed: {
    challenge() {
      return this.$store.state.challenges?.filter(
        (cll) =>
          cll.status.toLowerCase().startsWith("live") ||
          cll.status.toLowerCase().startsWith("created")
      )[0];
    },
    chronometer() {
      return this.$store.state.chronometer0.value;
    },
    displayChallengeBackgroundUrl() {
      return this.$store.state.displayChallengeBackgroundUrl;
    },
  },
});
</script>
<style scoped>
.container {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.score-container {
  width: 100%;
  height: 100%;
}

.bg-image {
  width: 100%;
  background-repeat: unset;
  background-position: center;
  background-size: cover;
}
</style>
