<template>
  <a-form
    :model="formState"
    v-bind="layout"
    autocomplete="off"
    name="nest-messages"
    @finish="onFinish"
  >
    <a-form-item
      :name="['tennis', 'title']"
      :label="this.$t('tennis.title')"
      :rules="[{ required: true, message: this.$t('required.title') }]"
    >
      <a-input v-model:value="formState.tennis.title" />
    </a-form-item>
    <a-form-item :name="['tennis', 'types']" :label="this.$t('tennis.type')">
      <a-switch
        v-model:checked="formState.tennis.types"
        :checked-children="this.$t('tennis.singles')"
        :un-checked-children="this.$t('tennis.doubles')"
      />
    </a-form-item>

    <!-- players -->
    <a-form-item
      :name="['tennis', 'players', 'player1', 'id1']"
      :label="
        formState.tennis.types
          ? this.$t('tennis.singles_player1')
          : this.$t('tennis.doubles1_player1')
      "
      :rules="[{ required: true, message: this.$t('required.player_name') }]"
    >
      <a-select
        v-model:value="formState.tennis.players.player1.id1"
        style="width: 260px"
        placeholder="Please select"
        show-search
        option-filter-prop="children"
        :filter-option="filterOption"
        :options="displayPlayers"
        :open="open11"
        @click="open11 = true"
        @change="handleChangePlayer11"
      >
        <template #dropdownRender="{ menuNode: menu }">
          <v-nodes :vnodes="menu" />
          <a-divider style="margin: 4px 0" />
          <a-space style="padding: 2px 3px">
            <a-space direction="vertical">
              <a-input
                v-model:value="newPlayerName"
                :placeholder="this.$t('common.name')"
              />
              <a-input
                v-model:value="newPlayerLastname"
                :placeholder="this.$t('common.lastname')"
              />
            </a-space>

            <a-button type="text" @click="addItem">
              <template #icon>
                <plus-outlined />
              </template>
              {{ $t("common.add") }}
            </a-button>
          </a-space>
        </template>
      </a-select>
    </a-form-item>

    <div v-if="!formState.tennis.types">
      <a-form-item
        :name="['tennis', 'players', 'player1', 'id2']"
        :label="this.$t('tennis.doubles1_player2')"
        :rules="[{ required: true, message: this.$t('required.player_name') }]"
      >
        <a-select
          v-model:value="formState.tennis.players.player1.id2"
          style="width: 250px"
          placeholder="Please select"
          show-search
          option-filter-prop="children"
          :filter-option="filterOption"
          :options="displayPlayers"
          :open="open12"
          @click="open12 = true"
          @change="handleChangePlayer12"
        >
          <template #dropdownRender="{ menuNode: menu }">
            <v-nodes :vnodes="menu" />
            <a-divider style="margin: 4px 0" />
            <a-space style="padding: 4px 8px">
              <a-input
                v-model:value="newPlayer"
                placeholder="Please enter item"
              />
              <a-button type="text" @click="addItem">
                <template #icon>
                  <plus-outlined />
                </template>
                Add item
              </a-button>
            </a-space>
          </template>
        </a-select>
      </a-form-item>
    </div>

    <a-form-item
      :name="['tennis', 'players', 'player2', 'id1']"
      :label="
        formState.tennis.types
          ? this.$t('tennis.singles_player2')
          : this.$t('tennis.doubles2_player1')
      "
      :rules="[{ required: true, message: this.$t('required.player_name') }]"
    >
      <a-select
        v-model:value="formState.tennis.players.player2.id1"
        style="width: 250px"
        placeholder="Please select"
        :options="displayPlayers"
        show-search
        option-filter-prop="children"
        :filter-option="filterOption"
        :open="open21"
        @click="open21 = true"
        @change="handleChangePlayer21"
      >
        <template #dropdownRender="{ menuNode: menu }">
          <v-nodes :vnodes="menu" />
          <a-divider style="margin: 4px 0" />
          <a-space style="padding: 2px 3px">
            <a-space direction="vertical">
              <a-input
                v-model:value="newPlayerName"
                :placeholder="this.$t('common.name')"
              />
              <a-input
                v-model:value="newPlayerLastname"
                :placeholder="this.$t('common.lastname')"
              />
            </a-space>

            <a-button type="text" @click="addItem">
              <template #icon>
                <plus-outlined />
              </template>
              {{ $t("common.add") }}
            </a-button>
          </a-space>
        </template>
      </a-select>
    </a-form-item>

    <div v-if="!formState.tennis.types">
      <a-form-item
        :name="['tennis', 'players', 'player2', 'id2']"
        :label="this.$t('tennis.doubles2_player2')"
        :rules="[{ required: true, message: this.$t('required.player_name') }]"
      >
        <a-select
          v-model:value="formState.tennis.players.player2.id2"
          style="width: 250px"
          placeholder="Please select"
          :open="open22"
          @click="open22 = true"
          show-search
          option-filter-prop="children"
          :filter-option="filterOption"
          :options="displayPlayers"
          @change="handleChangePlayer22"
        >
          <template #dropdownRender="{ menuNode: menu }">
            <v-nodes :vnodes="menu" />
            <a-divider style="margin: 4px 0" />
            <a-space style="padding: 2px 3px">
              <a-space direction="vertical">
                <a-input
                  v-model:value="newPlayerName"
                  :placeholder="this.$t('common.name')"
                />
                <a-input
                  v-model:value="newPlayerLastname"
                  :placeholder="this.$t('common.lastname')"
                />
              </a-space>

              <a-button type="text" @click="addItem">
                <template #icon>
                  <plus-outlined />
                </template>
                {{ $t("common.add") }}
              </a-button>
            </a-space>
          </template>
        </a-select>
      </a-form-item>
    </div>
    <!-- end players -->

    <a-form-item
      :name="['tennis', 'format']"
      :label="this.$t('tennis.format')"
      :rules="[{ required: true, message: this.$t('required.format') }]"
    >
      <a-radio-group
        v-model:value="formState.tennis.format"
        @change="formatChange"
      >
        <a-radio-button value="format1">{{
          $t("tennis.format1")
        }}</a-radio-button>
        <a-radio-button value="format2">{{
          $t("tennis.format2")
        }}</a-radio-button>
        <a-radio-button value="format3">{{
          $t("tennis.format3")
        }}</a-radio-button>
      </a-radio-group>
    </a-form-item>
    <div v-if="twoSet">
      <a-form-item
        :name="['tennis', 'superTiebreak']"
        :label="this.$t('tennis.apply_super_tiebreak')"
      >
        <a-switch
          v-model:checked="formState.tennis.superTiebreak"
          :checked-children="this.$t('common.yes')"
          :un-checked-children="this.$t('common.no')"
        />
      </a-form-item>
    </div>
    <a-form-item
      :name="['tennis', 'advantage']"
      :label="this.$t('tennis.advantage')"
    >
      <a-switch
        v-model:checked="formState.tennis.advantage"
        :checked-children="this.$t('tennis.ad')"
        :un-checked-children="this.$t('tennis.without_ad')"
      />
    </a-form-item>
    <a-form-item :wrapper-col="{ ...layout.wrapperCol, offset: 8 }">
      <a-button type="primary" html-type="submit">{{
        $t("common.create")
      }}</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      layout: {
        wrapperCol: { span: 16 },
      },
      formState: {
        tennis: {
          title: "",
          types: true,
          format: "",
          advantage: false,
          superTiebreak: false,
          players: {
            player1: {
              id1: undefined,
              id2: undefined,
              members: { nameA: "", nameB: "" },
            },
            player2: {
              id: undefined,
              members: { nameA: "", nameB: "" },
            },
          },
        },
      },
      open11: false,
      open12: false,
      open21: false,
      open22: false,
      newPlayerName: "",
      newPlayerLastname: "",
      newPlayer: "",
      value: "",
      players: [],
      twoSet: false,
    };
  },
  components: {
    PlusOutlined,
    VNodes: (_, { attrs }) => {
      return attrs.vnodes;
    },
  },
  methods: {
    getPlayers() {
      this.$store.dispatch("player/getCustom");
    },
    onFinish(values) {
      console.log("Success:", values);
      let root = {};
      let tennis = {};
      tennis.action = "created";
      tennis.data = values.tennis;
      root.tennis = tennis;
      console.log("json", JSON.stringify(root));
      this.$store.dispatch("sendMessage", JSON.stringify(root));
      this.clear();
      this.$emit("closeModal");
    },
    click(e) {
      console.log("click");
      e.preventDefault();
    },
    addItem(e) {
      console.log(this.displayPlayers);
      e.preventDefault();
      this.displayPlayers.unshift({
        label: `${this.newPlayerName} ${this.newPlayerLastname}`,
        value: `${this.newPlayerName}@@${this.newPlayerLastname}`,
      });
      this.newPlayerName = "";
      this.newPlayerLastname = "";
    },
    handleSearch(val) {
      console.log(val);
      //return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      // this.displayPlayers = this.displayPlayers.filter((item)=>{
      //   console.log(item.label, val);
      //   console.log("  ", item.label.includes(val));
      //   return item.label.includes(val)
      // })
    },
    handleChangePlayer11() {
      this.open11 = false;
    },
    filterOption(input, option) {
      console.log(input, option);
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    handleChangePlayer12() {
      this.open12 = false;
    },
    handleChangePlayer21() {
      this.open21 = false;
    },
    handleChangePlayer22() {
      this.open22 = false;
    },
    formatChange(val) {
      if (val.target.value === "format1") {
        this.twoSet = false;
      } else {
        this.twoSet = true;
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage.value = file.url || file.preview;
      this.previewVisible.value = true;
      this.previewTitle.value =
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1);
    },
    clear() {
      this.formState.tennis = {
        title: "",
        types: true,
        format: "",
        advantage: false,
        players: {
          player1: {
            id1: undefined,
            id2: undefined,
          },
          player2: {
            id1: undefined,
            id2: undefined,
          },
        },
      };
    },
  },
  mounted() {},
  created() {
    this.getPlayers();
  },
  computed: {
    displayPlayers() {
      return this.$store.state.player.custom;
    },
  },
});
</script>
